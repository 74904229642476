import { Alert, Button, Modal, TextInput } from "flowbite-react"
import { FC, PropsWithChildren, useEffect, useState } from "react"
import { PublicCourseSchema } from "../../api"
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"

export type ModalProps = PropsWithChildren<{
  course: PublicCourseSchema
  cloudProvider: string
  videoHours: number
  daysLab: number
  labCount: number
  errorMsg: string
  buy: (slug: string, quantity: string) => Promise<void>
}>

const ModalBuyCourse: FC<ModalProps> = ({
  course,
  cloudProvider,
  videoHours,
  daysLab,
  labCount,
  errorMsg,
  buy,
  children,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [quantity, setQuantity] = useState<string>("1")
  const [validQuantity, setValidQuantity] = useState(false)
  const [quantityFocus, setQuantityFocus] = useState(false)
  const [errModalMsg, setErrModalMsg] = useState("")

  useEffect(() => {
    const q = parseInt(quantity)
    setValidQuantity(q >= 1)
  }, [quantity])

  useEffect(() => {
    setErrModalMsg(errorMsg)
  }, [errorMsg])

  return (
    <>
      <div className="flex flex-col sm:items-start">
        <Button
          className={`items-center text-white ${
            cloudProvider === "AWS"
              ? "!bg-amber-500 hover:!bg-amber-600 !border-amber-500 !ring-amber-500"
              : cloudProvider === "GCP"
              ? "!bg-green-600 hover:!bg-green-700 !border-green-600 !ring-green-600"
              : "!bg-sky-400 hover:!bg-sky-500 !border-sky-400 !ring-sky-400"
          } !ring-2`}
          type="button"
          size="lg"
          onClick={() => setOpenModal(true)}
        >
          Buy a voucher
        </Button>
      </div>
      <Modal
        show={openModal}
        onClose={() => setOpenModal(false)}
        size={"lg"}
        dismissible={true}
        theme={{
          root: {
            base: "fixed inset-x-0 top-0 z-50 h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
            show: {
              on: "flex bg-neutral-900 bg-opacity-50 dark:bg-opacity-80",
              off: "hidden",
            },
            sizes: {
              sm: "max-w-sm",
              md: "max-w-md",
              lg: "max-w-lg",
              xl: "max-w-xl",
              "2xl": "max-w-2xl",
              "3xl": "max-w-3xl",
              "4xl": "max-w-4xl",
              "5xl": "max-w-5xl",
              "6xl": "max-w-6xl",
              "7xl": "max-w-7xl",
            },
            positions: {
              "top-left": "items-start justify-start",
              "top-center": "items-start justify-center",
              "top-right": "items-start justify-end",
              "center-left": "items-center justify-start",
              center: "items-center justify-center",
              "center-right": "items-center justify-end",
              "bottom-right": "items-end justify-end",
              "bottom-center": "items-end justify-center",
              "bottom-left": "items-end justify-start",
            },
          },
          content: {
            base: "relative h-full w-full p-4 md:h-auto",
            inner:
              "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-neutral-700",
          },
          body: {
            base: "flex-1 overflow-auto p-6",
            popup: "pt-0",
          },
          header: {
            base: "flex items-start justify-between rounded-t border-b p-5 dark:border-neutral-600",
            popup: "border-b-0 p-2",
            title: "text-xl font-medium text-neutral-900 dark:text-white",
            close: {
              base: "ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-neutral-400 hover:bg-neutral-200 hover:text-neutral-900 dark:hover:bg-neutral-600 dark:hover:text-white",
              icon: "h-5 w-5",
            },
          },
          footer: {
            base: "flex items-center space-x-2 rounded-b border-neutral-200 p-6 dark:border-neutral-600",
            popup: "border-t",
          },
        }}
      >
        <Modal.Header>{"Get " + course.title + " now"}</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div className="p-6">
              <div className="space-y-6">
                <div className={!errModalMsg ? "sr-only" : ""}>
                  <Alert
                    color="failure"
                    icon={ExclamationTriangleIcon}
                    onDismiss={function onDismiss() {
                      setErrModalMsg("")
                    }}
                  >
                    <span>{errModalMsg}</span>
                  </Alert>
                </div>
                {course.max_sales ? (
                  <Alert
                    className=""
                    color="warning"
                    icon={ExclamationTriangleIcon}
                  >
                    To ensure the best quality of our service, for the time
                    being, we are limiting this course to {course.max_sales}{" "}
                    students.
                  </Alert>
                ) : (
                  <></>
                )}
                {course.prereleased ? (
                  <div className="w-full">
                    <>
                      <Alert
                        className=""
                        color="warning"
                        icon={ExclamationTriangleIcon}
                      >
                        This course will not be released until{" "}
                        <strong>{course.release_date}</strong>. You can purchase
                        your course voucher now but won't be able to activate it
                        until the release date.
                      </Alert>
                    </>
                  </div>
                ) : (
                  <></>
                )}
                <div className="text-base leading-relaxed text-neutral-700 dark:text-white">
                  Includes:
                  <ul className="list-disc list-inside">
                    <li>{videoHours}+ hours of video lessons</li>
                    <li>
                      {daysLab} days of access to {labCount}+ labs
                    </li>
                    <li>
                      {course.has_exam
                        ? "1 certification exam attempt"
                        : "1 certificate of completion"}
                    </li>
                  </ul>
                </div>
                <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center">
                  Price: {course.price}€
                </div>
                <div className="flex flex-col items-center">
                  <TextInput
                    id="quantity"
                    type="number"
                    placeholder="1"
                    addon="Quantity"
                    required={true}
                    onChange={(e) => {
                      if (
                        parseInt(e.target.value) > 0 ||
                        e.target.value === ""
                      ) {
                        setQuantity(e.target.value)
                      }
                    }}
                    onFocus={() => setQuantityFocus(true)}
                    onBlur={() => setQuantityFocus(false)}
                    value={quantity}
                    aria-invalid={validQuantity ? "false" : "true"}
                    color={validQuantity ? "" : !quantity ? "" : "failure"}
                    theme={{ field: { base: "relative w-16" } }}
                    helperText={
                      <>
                        <span
                          className={
                            quantityFocus && quantity && !validQuantity
                              ? ""
                              : "sr-only"
                          }
                        >
                          <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                          Invalid quantity, must be greater than 0
                        </span>
                      </>
                    }
                  />
                </div>

                {course?.promotion ? (
                  <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center ">
                    <Alert
                      className="mb-3"
                      color="failure"
                      icon={ExclamationCircleIcon}
                    >
                      Take advantage of our{" "}
                      <strong>early bird discount!</strong> You can purchase
                      your voucher now with the discount and activate it at any
                      point in the next year.
                    </Alert>
                    <span className="text-red-700 dark:text-red-500 line-through">
                      Total:{" "}
                      {course?.price * (quantity ? parseInt(quantity) : 0)}€
                    </span>
                    <br />
                    Promotion: -{course?.promotion}€ <br />
                    <span className="text-green-600 dark:text-green-400">
                      New total:{" "}
                      {course?.price * (quantity ? parseInt(quantity) : 0) -
                        course?.promotion}
                      €
                    </span>
                  </div>
                ) : (
                  <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center">
                    Total:
                    {course?.price * (quantity ? parseInt(quantity) : 0)}€
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="mx-auto text-neutral-700 dark:text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
            type="button"
            onClick={() => buy(course.slug, quantity)}
            disabled={!validQuantity}
          >
            Buy now
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalBuyCourse
