import {
  FaXTwitter,
  FaLinkedin,
  FaYoutube,
  FaGithub,
  FaDiscord,
  FaTwitch,
} from "react-icons/fa6"
import { Footer as FlowbiteFooter } from "flowbite-react"
import HT_TRAINING_logo_letters_horiz_big from "../assets/images/HT_TRAINING_logo_letters_horiz_big.svg"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <FlowbiteFooter
      container={true}
      className="!bg-neutral-300 dark:!bg-neutral-900 rounded-b-none"
    >
      <div className="container mx-auto">
        <div className="w-full">
          <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
            <div>
              <Link to="/">
                <img
                  src={HT_TRAINING_logo_letters_horiz_big}
                  className="mr-3 h-24 sm:h-24"
                  alt="HackTricks Training Logo"
                />
              </Link>
              <p className="text-xs text-neutral-800 dark:text-neutral-400 max-w-lg">
                Disclaimer: The information provided in the HackTricks courses
                is intended solely for educational purposes. It aims to teach
                security professionals cloud security concepts. Our courses are
                not intended to encourage or support unauthorized attacks on
                cloud infrastructure. Users are advised to apply this knowledge
                responsibly and adhere to all applicable laws.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-8 mt-10 sm:mt-4 sm:grid-cols-2 sm:gap-6 md:grid-cols-4">
              <div>
                <FlowbiteFooter.Title
                  title="Courses"
                  className="text-neutral-900 dark:text-white"
                />
                <FlowbiteFooter.LinkGroup col={true}>
                  {/* TODO make this dynamic ? */}
                  <FlowbiteFooter.Link
                    href="/courses/arte"
                    className="text-neutral-900 dark:text-white"
                  >
                    ARTE
                  </FlowbiteFooter.Link>
                  <FlowbiteFooter.Link
                    href="/courses/arta"
                    className="text-neutral-900 dark:text-white"
                  >
                    ARTA
                  </FlowbiteFooter.Link>
                  <FlowbiteFooter.Link
                    href="/courses/grte"
                    className="text-neutral-900 dark:text-white"
                  >
                    GRTE
                  </FlowbiteFooter.Link>
                </FlowbiteFooter.LinkGroup>
              </div>
              <div>
                <FlowbiteFooter.Title
                  title="About"
                  className="text-neutral-900 dark:text-white"
                />
                <FlowbiteFooter.LinkGroup col={true}>
                  {/* TODO this link should use the router needs changes to Flowbite */}
                  {/* <FlowbiteFooter.Link href="/about" className="text-neutral-900 dark:text-white">
                    Who are we?
                  </FlowbiteFooter.Link> */}
                  {/* TODO this link should use the router needs changes to Flowbite */}
                  <FlowbiteFooter.Link
                    href="/contact"
                    className="text-neutral-900 dark:text-white"
                  >
                    Get in touch
                  </FlowbiteFooter.Link>
                  {/* TODO this link should use the router needs changes to Flowbite */}
                  <FlowbiteFooter.Link
                    href="/faqs"
                    className="text-neutral-900 dark:text-white"
                  >
                    FAQs
                  </FlowbiteFooter.Link>
                </FlowbiteFooter.LinkGroup>
              </div>
              <div>
                <FlowbiteFooter.Title
                  title="Resources"
                  className="text-neutral-900 dark:text-white"
                />
                <FlowbiteFooter.LinkGroup col={true}>
                  <FlowbiteFooter.Link
                    href="https://book.hacktricks.xyz/"
                    className="text-neutral-900 dark:text-white"
                  >
                    HackTricks
                  </FlowbiteFooter.Link>
                  <FlowbiteFooter.Link
                    href="https://cloud.hacktricks.xyz/"
                    className="text-neutral-900 dark:text-white"
                  >
                    HackTricks Cloud
                  </FlowbiteFooter.Link>
                </FlowbiteFooter.LinkGroup>
              </div>
              <div>
                <FlowbiteFooter.Title
                  title="Legal"
                  className="text-neutral-900 dark:text-white"
                />
                <FlowbiteFooter.LinkGroup col={true}>
                  {/* TODO this link should use the router needs changes to Flowbite */}
                  <FlowbiteFooter.Link
                    href="/terms"
                    className="text-neutral-900 dark:text-white"
                  >
                    Terms & Conditions
                  </FlowbiteFooter.Link>
                  <FlowbiteFooter.Link
                    href="/privacy"
                    className="text-neutral-900 dark:text-white"
                  >
                    Privacy Policy
                  </FlowbiteFooter.Link>
                </FlowbiteFooter.LinkGroup>
              </div>
            </div>
          </div>
          <FlowbiteFooter.Divider
            theme={{
              base: "my-6 w-full dark:border-neutral-700 sm:mx-auto lg:my-8",
            }}
            className="border-neutral-700"
          />
          <div className="w-full sm:flex sm:items-center sm:justify-between">
            <FlowbiteFooter.Copyright
              theme={{
                base: "text-sm text-neutral-500 sm:text-center",
                href: "ml-1 hover:underline",
                span: "ml-1",
              }}
              href="#"
              by="HackTricks"
              year={2024}
            />
            <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
              <FlowbiteFooter.Icon
                href="https://twitter.com/hacktricks_live"
                icon={FaXTwitter}
                className="text-neutral-500 hover:text-white"
              />
              <FlowbiteFooter.Icon
                href="https://www.linkedin.com/company/hacktricks/"
                className="text-neutral-500 hover:text-white"
                icon={FaLinkedin}
              />
              <FlowbiteFooter.Icon
                href="https://www.twitch.tv/hacktricks_live"
                icon={FaTwitch}
                className="text-neutral-500 hover:text-white"
              />
              <FlowbiteFooter.Icon
                href="https://www.youtube.com/@hacktricks_LIVE"
                icon={FaYoutube}
                className="text-neutral-500 hover:text-white"
              />
              <FlowbiteFooter.Icon
                href="https://github.com/carlospolop/"
                icon={FaGithub}
                className="text-neutral-500 hover:text-white"
              />
              <FlowbiteFooter.Icon
                href="https://discord.gg/hRep4RUj7f"
                icon={FaDiscord}
                className="text-neutral-500 hover:text-white"
              />
            </div>
          </div>
        </div>
      </div>
    </FlowbiteFooter>
  )
}

export default Footer
