import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import { PrivateVoucherSchema } from "../../api"
import useAuthedApi from "../../hooks/useAuthedApi"
import { useState, useEffect, useRef, useMemo } from "react"
import { isAxiosError } from "axios"
import { Alert, Button, Checkbox, Modal, Tooltip } from "flowbite-react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import { TbMailForward } from "react-icons/tb"
import useToasts from "../../hooks/useToasts"
import BasicTable from "../../components/tables/BasicTable"
import { ColumnDef } from "@tanstack/react-table"
import { HiOutlineExclamationCircle } from "react-icons/hi"

const Vouchers = () => {
  const [vouchers, setVouchers] = useState<PrivateVoucherSchema[]>()
  const { VouchersAuthedApi } = useAuthedApi()
  const [showResendModal, setShowResendModal] = useState(false)
  const [voucherToResend, setVoucherToResend] = useState<PrivateVoucherSchema>()
  const [resendErrMsg, setResendErrMsg] = useState("")
  const resendErrRef = useRef<HTMLInputElement>(null)
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const { toastList, setToastList } = useToasts()

  const getVouchers = async () => {
    try {
      const response = await VouchersAuthedApi.vouchersReadVouchersMe()
      if (!response.data) {
        setErrMsg("Get vouchers failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setVouchers(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get vouchers failed with unknown error")
        }
      } else {
        setErrMsg("Get vouchers failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const reSendVouchers = async (voucher: PrivateVoucherSchema | undefined) => {
    if (!voucher) {
      setResendErrMsg(`No voucher to resend`)
      return
    }
    try {
      const response = await VouchersAuthedApi.vouchersResendVoucher({
        id: voucher.id,
      })
      if (!response.data) {
        setResendErrMsg("Re-send vouchers email failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          children: "Voucher email sent!",
        })
      )
      setShowResendModal(false)
      setVoucherToResend(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setResendErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setResendErrMsg("Data validation error")
        } else {
          setResendErrMsg("Re-send vouchers email failed with unknown error")
        }
      } else {
        setResendErrMsg("Re-send vouchers email failed with unknown error")
      }
      resendErrRef.current?.focus()
    }
  }

  useEffect(() => {
    getVouchers()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo<ColumnDef<PrivateVoucherSchema>[]>(
    () => [
      {
        header: "VOUCHER ID",
        footer: (props) => props.column.id,
        accessorKey: "id",
        cell: (props) => (
          <span className="font-medium text-gray-900 dark:text-white">
            {props.row.original.id}
          </span>
        ),
      },
      {
        header: "TYPE",
        footer: (props) => props.column.id,
        accessorKey: "type",
        cell: (props) => props.getValue(),
      },
      // TODO support other types of vouchers not just Course
      {
        header: "TITLE",
        footer: (props) => props.column.id,
        accessorKey: "course.title",
        cell: (props) => props.getValue(),
      },
      {
        header: "USED",
        footer: (props) => props.column.id,
        accessorKey: "is_used",
        cell: (props) => (
          <Checkbox
            theme={{
              root: {
                base: "h-4 w-4 rounded border border-neutral-300 bg-neutral-100 focus:ring-2 focus:ring-red-logo dark:border-neutral-600 dark:bg-neutral-700 dark:ring-offset-neutral-800 dark:focus:ring-red-logo",
              },
            }}
            checked={props.getValue() ? true : false}
            disabled={true}
          />
        ),
      },
      {
        header: "REDEEMED AT",
        footer: (props) => props.column.id,
        accessorKey: "redeemed_at",
        cell: (props) => props.getValue(),
        enableSorting: false,
      },
      {
        id: "actions",
        footer: (props) => props.column.id,
        cell: (props) =>
          props.row.original.is_used ? (
            <></>
          ) : (
            <Tooltip
              content="Send voucher again"
              className="bg-neutral-900 text-white dark:bg-neutral-800"
              theme={{
                arrow: {
                  base: "absolute z-10 h-2 w-2 rotate-45 bg-neutral-900 dark:bg-neutral-800",
                },
              }}
            >
              <button
                onClick={() => {
                  setShowResendModal(true)
                  setVoucherToResend(props.row.original)
                }}
                className="font-medium text-center text-red-logo hover:text-red-900 hover:underline cursor-pointer"
              >
                <TbMailForward size={20} />
              </button>
            </Tooltip>
          ),
      },
    ],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <section className="min-h-screen flex flex-col justify-between">
      <NavbarPrivate />
      <div className="mb-auto">
        <div className="container mx-auto">
          <section id="vouchers">
            <h1 className="text-center text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mt-8 mb-5 md:mb-10 mx-3 md:mx-0">
              VOUCHERS
            </h1>
            <Modal
              size="md"
              dismissible={true}
              popup={true}
              show={showResendModal}
              onClose={() => {
                setShowResendModal(false)
                setVoucherToResend(undefined)
              }}
            >
              <Modal.Header />
              <Modal.Body>
                <div className="text-center">
                  <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                  <div className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    Are you sure you want send this voucher again{" "}
                    {voucherToResend?.id}?
                  </div>
                  <div className={!resendErrMsg ? "sr-only" : ""}>
                    <Alert
                      color="failure"
                      icon={ExclamationTriangleIcon}
                      onDismiss={function onDismiss() {
                        setResendErrMsg("")
                      }}
                    >
                      <span ref={resendErrRef}>{resendErrMsg}</span>
                    </Alert>
                  </div>
                  <div className="flex justify-center gap-4">
                    <Button
                      color="success"
                      onClick={() => reSendVouchers(voucherToResend)}
                    >
                      Yes, I'm sure
                    </Button>
                    <Button
                      color="gray"
                      onClick={() => setShowResendModal(false)}
                    >
                      No, cancel
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <div className={!errMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setErrMsg("")
                }}
              >
                <span ref={errRef}>{errMsg}</span>
              </Alert>
            </div>
            <div className="overflow-x-auto mx-3 md:mx-0 mb-10">
              {vouchers?.length ? (
                <BasicTable columns={columns} data={vouchers} />
              ) : (
                <Alert color="warning" icon={ExclamationTriangleIcon}>
                  <span>No vouchers to display</span>
                </Alert>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Vouchers
