import { NavbarLink } from "flowbite-react"
import { Link } from "react-router-dom"
import NavbarCoursesDropdown from "./NavbarCoursesDropdown"
import NavbarCustom from "./NavbarCustom"

const NavbarPrivate = () => {
  return (
    <NavbarCustom>
      <NavbarLink
        as={Link}
        to="/dashboard"
        theme={{
          base: "block py-2 pr-4 pl-3 md:p-0 text-white hover:text-neutral-400 cursor-pointer font-bold uppercase font-hacktricks",
          active: {
            off: "text-neutral-900 dark:text-white hover:text-neutral-400",
            on: "text-neutral-900 dark:text-white",
          },
        }}
      >
        Dashboard
      </NavbarLink>

      <NavbarCoursesDropdown />

      <NavbarLink
        as={Link}
        to="/vouchers"
        theme={{
          base: "block py-2 pr-4 pl-3 md:p-0 text-white hover:text-neutral-400 cursor-pointer font-bold uppercase font-hacktricks",
          active: {
            off: "text-neutral-900 dark:text-white hover:text-neutral-400",
            on: "text-neutral-900 dark:text-white",
          },
        }}
      >
        Vouchers
      </NavbarLink>
    </NavbarCustom>
  )
}

export default NavbarPrivate
