import { Avatar, Button, DarkThemeToggle, Dropdown } from "flowbite-react"
import { Link } from "react-router-dom"
import { CustomDarkThemeToggle } from "../CustomDarkThemeToggle"
import { FC, PropsWithChildren } from "react"
import { AuthData } from "../../hooks/useAuth"

export type NavbarUserDropdownProps = PropsWithChildren<{
  auth?: AuthData
  signout: () => {}
}>

const NavbarUserDropdown: FC<NavbarUserDropdownProps> = ({
  auth,
  signout,
  children,
}) => {
  return (
    <>
      {auth && auth.user ? (
        <Dropdown
          arrowIcon={false}
          inline={true}
          theme={{
            floating: {
              divider: "my-1 h-px !bg-neutral-100 dark:!bg-neutral-600",
            },
          }}
          label={
            <Avatar
              alt="User dropdown"
              placeholderInitials={
                auth.user?.first_name.charAt(0).toUpperCase() +
                auth.user?.last_name.charAt(0).toUpperCase()
              }
              rounded={true}
              theme={{
                root: {
                  initials: {
                    base: "rounded relative overflow-hidden bg-neutral-100 dark:bg-neutral-600 inline-flex overflow-hidden relative justify-center items-center bg-neutral-100 dark:bg-neutral-700 !rounded-full w-10 h-10",
                  },
                },
              }}
            />
          }
          className="dark:bg-neutral-700 w-96"
        >
          <Dropdown.Header>
            <span className="block text-sm">{auth.user?.username}</span>
            <span className="block truncate text-sm font-medium">
              {auth.user?.email}
            </span>
          </Dropdown.Header>
          {auth.user?.roles.includes("admin") ? (
            <Link to="/admin">
              <Dropdown.Item
                theme={{
                  base: "flex items-center justify-start py-2 px-4 text-sm text-neutral-700 cursor-pointer w-full hover:bg-neutral-100 focus:bg-neutral-100 dark:text-neutral-200 dark:hover:bg-neutral-600 focus:outline-none dark:hover:text-white dark:focus:bg-neutral-600 dark:focus:text-white",
                }}
              >
                Admin panel
              </Dropdown.Item>
            </Link>
          ) : (
            <></>
          )}
          <Link to="/profile">
            <Dropdown.Item
              theme={{
                base: "flex items-center justify-start py-2 px-4 text-sm text-neutral-700 cursor-pointer w-full hover:bg-neutral-100 focus:bg-neutral-100 dark:text-neutral-200 dark:hover:bg-neutral-600 focus:outline-none dark:hover:text-white dark:focus:bg-neutral-600 dark:focus:text-white",
              }}
            >
              Profile
            </Dropdown.Item>
          </Link>
          <Link to="/certificates">
            <Dropdown.Item
              theme={{
                base: "flex items-center justify-start py-2 px-4 text-sm text-neutral-700 cursor-pointer w-full hover:bg-neutral-100 focus:bg-neutral-100 dark:text-neutral-200 dark:hover:bg-neutral-600 focus:outline-none dark:hover:text-white dark:focus:bg-neutral-600 dark:focus:text-white",
              }}
            >
              Certificates
            </Dropdown.Item>
          </Link>
          <div className="flex items-center justify-start py-2 px-4 text-sm text-neutral-700 cursor-pointer hover:bg-neutral-100 dark:text-neutral-200 dark:hover:bg-neutral-600 dark:hover:text-white">
            <span>Dark mode</span>
            <CustomDarkThemeToggle className="ml-auto" />
          </div>
          <Dropdown.Divider />
          <Dropdown.Item
            theme={{
              base: "flex items-center justify-start py-2 px-4 text-sm text-neutral-700 cursor-pointer w-full hover:bg-neutral-100 focus:bg-neutral-100 dark:text-neutral-200 dark:hover:bg-neutral-600 focus:outline-none dark:hover:text-white dark:focus:bg-neutral-600 dark:focus:text-white",
            }}
            onClick={() => {
              signout()
            }}
          >
            Sign out
          </Dropdown.Item>
        </Dropdown>
      ) : (
        <>
          <Button
            as={Link}
            to="/signin"
            outline={true}
            className="dark:dark md:!ml-3 mb-4 md:mb-0 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
          >
            Sign in
          </Button>
          <Button
            as={Link}
            to="/signup"
            className="md:!ml-3 mb-4 md:mb-0 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
          >
            Create an account
          </Button>

          <DarkThemeToggle
            theme={{
              root: {
                base: "md:!ml-3 rounded-lg p-2.5 text-sm text-neutral-500 hover:bg-neutral-100 focus:outline-none focus:ring-4 focus:ring-neutral-200 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:focus:ring-neutral-700",
              },
            }}
          />
        </>
      )}
    </>
  )
}

export default NavbarUserDropdown
