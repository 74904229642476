import { useRef, useState, useEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { isAxiosError } from "axios"
import useApi from "../../hooks/useApi"
import { Alert } from "flowbite-react"
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid"
import {
  PublicCourseSchema,
  PublicExamSchema,
  PublicLabSchema,
} from "../../api"

const PurchaseSuccess = () => {
  const { CoursesApi, LabsApi, ExamsApi } = useApi()
  const [searchParams] = useSearchParams()

  const errRef = useRef<HTMLInputElement>(null)
  const [course, setCourse] = useState<PublicCourseSchema>()
  const [lab, setLab] = useState<PublicLabSchema>()
  const [exam, setExam] = useState<PublicExamSchema>()

  const [errMsg, setErrMsg] = useState("")

  useEffect(() => {
    const course = searchParams.get("course")
    if (course !== null) {
      getCourse(course)
    }
    const lab = searchParams.get("lab")
    if (lab !== null) {
      getLab(lab)
    }
    const exam = searchParams.get("exam")
    if (exam !== null) {
      getExam(exam)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCourse = async (course: string) => {
    try {
      const response = await CoursesApi.coursesReadCourse(course)
      if (!response.data) {
        setErrMsg("Get course failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setCourse(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 404) {
          setErrMsg("We could not find that course")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get course failed with unknown error")
        }
      } else {
        setErrMsg("Get course failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const getLab = async (lab: string) => {
    try {
      const response = await LabsApi.labsReadLab(lab)
      if (!response.data) {
        setErrMsg("Get lab failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setLab(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 404) {
          setErrMsg("We could not find that lab")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get lab failed with unknown error")
        }
      } else {
        setErrMsg("Get lab failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const getExam = async (exam: string) => {
    try {
      const response = await ExamsApi.examsReadExam(exam)
      if (!response.data) {
        setErrMsg("Get exam failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setExam(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 404) {
          setErrMsg("We could not find that exam")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get exam failed with unknown error")
        }
      } else {
        setErrMsg("Get exam failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  return (
    <section>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="HackTricks Training"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
              Thanks for purchasing a voucher!
            </h2>
          </div>
          <div className={!errMsg ? "sr-only" : ""}>
            <Alert
              color="failure"
              icon={ExclamationCircleIcon}
              onDismiss={function onDismiss() {
                setErrMsg("")
              }}
            >
              <span ref={errRef}>{errMsg}</span>
            </Alert>
          </div>
          {course ? (
            <div>
              <Alert color="success" icon={CheckCircleIcon}>
                <span>
                  Your voucher for the course <strong>{course.title}</strong>{" "}
                  will be sent via email.
                  <br />
                  <br />
                  The email will contain instructions on how to activate the
                  voucher.
                  <br />
                  <br />
                  <Link
                    to="/courses"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Explore more courses
                  </Link>
                  <br />
                  <Link
                    to="/dashboard"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Go to your dashboard
                  </Link>
                </span>
              </Alert>
            </div>
          ) : (
            <>
              {lab ? (
                <div>
                  <Alert color="success" icon={CheckCircleIcon}>
                    <span>
                      Your voucher for the lab <strong>{lab.title}</strong> will
                      be sent via email.
                      <br />
                      <br />
                      The email will contain instructions on how to activate the
                      voucher.
                      <br />
                      <br />
                      <Link
                        to="/courses"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Explore more courses
                      </Link>
                      <br />
                      <Link
                        to="/dashboard"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Go to your dashboard
                      </Link>
                    </span>
                  </Alert>
                </div>
              ) : (
                <>
                  {exam ? (
                    <div>
                      <Alert color="success" icon={CheckCircleIcon}>
                        <span>
                          Your voucher for the exam{" "}
                          <strong>{exam.title}</strong> will be sent via email.
                          <br />
                          <br />
                          The email will contain instructions on how to activate
                          the voucher.
                          <br />
                          <br />
                          <Link
                            to="/courses"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            Explore more courses
                          </Link>
                          <br />
                          <Link
                            to="/dashboard"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            Go to your dashboard
                          </Link>
                        </span>
                      </Alert>
                    </div>
                  ) : (
                    <Alert color="success" icon={CheckCircleIcon}>
                      <span>Nothing to display</span>
                    </Alert>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default PurchaseSuccess
