import "./App.css"
import Landing from "./pages/landing/Landing"
import Signup from "./pages/auth/Signup"
import Signin from "./pages/auth/Signin"
import ForgotPassword from "./pages/auth/ForgotPassword"
import ResetPassword from "./pages/auth/ResetPassword"
import { Routes, Route, useSearchParams } from "react-router-dom"
import Layout from "./components/Layout"
import RequireAuth from "./components/RequireAuth"
import PersistAuth from "./components/PersistAuth"
import NotFound from "./pages/errors/NotFound"
import Unauthorized from "./pages/errors/Unauthorized"

import Dashboard from "./pages/dashboard/Dashboard"
import Profile from "./pages/user/Profile"
import Courses from "./pages/courses/Courses"
import ActivateAccount from "./pages/auth/ActivateAccount"
import InactiveAccount from "./pages/auth/InactiveAccount"
import Course from "./pages/courses/Course"
import ActivateVoucher from "./pages/vouchers/ActivateVoucher"
import PurchaseSuccess from "./pages/vouchers/PurchaseSuccess"
import PurchaseCancel from "./pages/vouchers/PurchaseCancel"
import Vouchers from "./pages/vouchers/Vouchers"
// import About from "./pages/about/About"
import Contact from "./pages/contact/Contact"
import Terms from "./pages/legal/Terms"
import Privacy from "./pages/legal/Privacy"
import AdminDashboard from "./pages/admin/AdminDashboard"
import AdminVouchers from "./pages/admin/AdminVouchers"
import AdminCertificates from "./pages/admin/AdminCertificates"
import AdminCourses from "./pages/admin/AdminCourses"
import AdminUsers from "./pages/admin/AdminUsers"
import FAQsPage from "./pages/legal/FAQsPage"
import AdminAWSAccounts from "./pages/admin/AdminAWSAccounts"
import AdminGCPProjects from "./pages/admin/AdminGCPProjects"
import Classroom from "./pages/classroom/Classroom"
import AdminCourse from "./pages/admin/AdminCourse"
import AdminLabs from "./pages/admin/AdminLabs"
import AdminLab from "./pages/admin/AdminLab"
import AdminExams from "./pages/admin/AdminExams"
import AdminExam from "./pages/admin/AdminExam"
import Examroom from "./pages/examroom/Examroom"
import Certificates from "./pages/certificates/Certificates"
import Certificate from "./pages/certificates/Certificate"
import Feedback from "./pages/certificates/Feedback"
import { useCookies } from "react-cookie"
import { useEffect } from "react"

export default function App() {
  const [searchParams] = useSearchParams()
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies(["referrer"])

  useEffect(() => {
    let referrer = searchParams.get("referrer")
    if (!referrer) {
      return
    }
    console.log(referrer)
    setCookie("referrer", referrer, { maxAge: 60 * 60 * 6 })
  }, [searchParams, setCookie])

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/" element={<Landing />} />
        <Route path="signin" element={<Signin />} />
        <Route path="signup" element={<Signup />} />
        <Route path="inactive_account" element={<InactiveAccount />} />
        <Route path="activate_account" element={<ActivateAccount />} />
        <Route path="forgot_password" element={<ForgotPassword />} />
        <Route path="reset_password" element={<ResetPassword />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/course/:slug" element={<Course />} />
        <Route path="/courses/:slug" element={<Course />} />
        <Route path="/certificates/:id" element={<Certificate />} />
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/faqs" element={<FAQsPage />} />

        {/* we want to protect these routes */}
        <Route element={<PersistAuth />}>
          <Route element={<RequireAuth allowedRoles={["user"]} />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/activate_voucher" element={<ActivateVoucher />} />
            <Route path="/vouchers" element={<Vouchers />} />
            <Route path="/certificates" element={<Certificates />} />
            <Route path="/feedback/:slug" element={<Feedback />} />
            <Route
              path="/purchase_voucher_success"
              element={<PurchaseSuccess />}
            />
            <Route
              path="/purchase_voucher_cancel"
              element={<PurchaseCancel />}
            />
            <Route path="/classroom/:slug" element={<Classroom />} />
            <Route path="/examroom/:slug" element={<Examroom />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={["admin"]} />}>
            <Route path="admin" element={<AdminDashboard />} />
            <Route path="admin/users" element={<AdminUsers />} />
            <Route path="admin/courses" element={<AdminCourses />} />
            <Route path="admin/courses/:slug" element={<AdminCourse />} />
            <Route path="admin/labs" element={<AdminLabs />} />
            <Route path="admin/labs/:slug" element={<AdminLab />} />
            <Route path="admin/exams" element={<AdminExams />} />
            <Route path="admin/exams/:slug" element={<AdminExam />} />
            <Route path="admin/vouchers" element={<AdminVouchers />} />
            <Route path="admin/certificates" element={<AdminCertificates />} />
            <Route path="admin/aws_accounts" element={<AdminAWSAccounts />} />
            <Route path="admin/gcp_projects" element={<AdminGCPProjects />} />
          </Route>
        </Route>
        {/* catch all */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}
