import {
  Accordion,
  Alert,
  Button,
  Checkbox,
  Label,
  Modal,
  Progress,
  Tabs,
  TextInput,
  Textarea,
  Tooltip,
} from "flowbite-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import AdminSidebar from "../../components/sidebar/AdminSidebar"
import {
  AdminCourseSchema,
  AdminSectionSchema,
  AdminUserCourseSchema,
  EditCourseRequest,
} from "../../api"
import useAuthedApi from "../../hooks/useAuthedApi"
import useToasts from "../../hooks/useToasts"
import { useNavigate, useParams } from "react-router-dom"
import { isAxiosError } from "axios"
import { MdDelete, MdNumbers, MdTitle } from "react-icons/md"
import { HiOutlineExclamationCircle } from "react-icons/hi"
import { FaDiscord, FaHashtag } from "react-icons/fa"
import { BsCursorText, BsFillCalendarDateFill } from "react-icons/bs"
import { AiFillPicture } from "react-icons/ai"
import {
  BiArrowToTop,
  BiBarcodeReader,
  BiDollarCircle,
  BiSolidDashboard,
  BiSolidOffer,
} from "react-icons/bi"
import { VscJson } from "react-icons/vsc"
import AdminTable from "../../components/tables/AdminTable"
import { ColumnDef } from "@tanstack/react-table"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"

const AdminCourse = () => {
  const [course, setCourse] = useState<AdminCourseSchema>()
  const { AdminAuthedApi } = useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const { toastList, setToastList } = useToasts()
  const { slug } = useParams()
  const navigate = useNavigate()
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [logo, setLogo] = useState("")
  const [syllabus, setSyllabus] = useState("")
  const [faqs, setFaqs] = useState("")
  const [discordRoles, setDiscordRoles] = useState<string[]>([])
  const [hidden, setHidden] = useState(false)
  const [prereleased, setPrereleased] = useState(false)
  const [releaseDate, setReleaseDate] = useState<string | undefined>("")
  const [promotion, setPromotion] = useState<number | undefined>()
  const [maxSales, setMaxSales] = useState<number | undefined>()
  const [salesCount, setSalesCount] = useState<number | undefined>()
  const [promotionCode, setPromotionCode] = useState<string | undefined>("")
  const [price, setPrice] = useState(0)
  const [hasExam, setHasExam] = useState(true)
  const [sections, setSections] = useState<AdminSectionSchema[]>([])
  const [sectionsJSON, setSectionsJSON] = useState("")
  const [showEditModal, setShowEditModal] = useState(false)
  const [editErrMsg, setEditErrMsg] = useState("")
  const editErrRef = useRef<HTMLInputElement>(null)
  const [showEditSectionsModal, setShowEditSectionsModal] = useState(false)
  const [editSectionsErrMsg, setEditSectionsErrMsg] = useState("")
  const editSectionsErrRef = useRef<HTMLInputElement>(null)
  const [usercourse_associations, setUserCourseAssociations] =
    useState<AdminUserCourseSchema[]>()
  const [showRemoveFromCourseModal, setShowRemoveFromCourseModal] =
    useState(false)
  const [userToRemoveFromCourse, setUserToRemoveFromCourse] =
    useState<AdminUserCourseSchema>()
  const [removeFromCourseErrMsg, setRemoveFromCourseErrMsg] = useState("")
  const removeFromCourseErrRef = useRef<HTMLInputElement>(null)
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    window.innerWidth < 640
  )

  const getCourse = async (slug: string) => {
    try {
      const response = await AdminAuthedApi.adminReadCourse(slug)
      if (!response.data) {
        setErrMsg("Get courses failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setCourse(response.data)
      setTitle(response.data.title)
      setDescription(response.data.description)
      setLogo(response.data.logo)
      setSyllabus(JSON.stringify(response.data.syllabus, null, 2))
      setFaqs(JSON.stringify(response.data.faqs, null, 2))
      setHidden(response.data.hidden)
      setPrereleased(response.data.prereleased)
      setReleaseDate(response.data.release_date)
      setDiscordRoles(response.data.discord_roles)
      setPromotion(
        response.data.promotion == null ? undefined : response.data.promotion
      )
      setPromotionCode(
        response.data.promotion_code == null
          ? undefined
          : response.data.promotion_code
      )
      setMaxSales(
        response.data.max_sales == null ? undefined : response.data.max_sales
      )
      setSalesCount(
        response.data.sales_count == null
          ? undefined
          : response.data.sales_count
      )
      setPrice(response.data.price)
      setHasExam(response.data.has_exam)
      setSections(response.data.sections)
      setSectionsJSON(JSON.stringify(response.data.sections, null, 2))
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get courses failed with unknown error")
        }
      } else {
        setErrMsg("Get courses failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const getUserCourseAssociations = async (slug: string) => {
    try {
      const response = await AdminAuthedApi.adminReadCourseUsers(slug)
      if (!response.data) {
        setErrMsg("Get user course associations failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setUserCourseAssociations(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get user course associations failed with unknown error")
        }
      } else {
        setErrMsg("Get user course associations failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  useEffect(() => {
    if (!slug) {
      navigate("/admin/courses")
      return
    }
    getCourse(slug)
    getUserCourseAssociations(slug)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editCourse = async (course: AdminCourseSchema | undefined) => {
    if (!course) {
      setEditErrMsg(`No course to edit`)
      return
    }

    try {
      let payload: EditCourseRequest = {
        title: title,
        description: description,
        logo: logo,
        syllabus: JSON.parse(syllabus),
        faqs: JSON.parse(faqs),
        hidden: hidden,
        price: price,
        prereleased: prereleased,
        promotion: promotion,
        promotion_code: promotionCode,
        max_sales: maxSales,
        sales_count: salesCount,
        release_date: releaseDate,
        discord_roles: discordRoles,
        has_exam: hasExam,
      }
      const response = await AdminAuthedApi.adminEditCourse(
        course.slug,
        payload
      )
      if (!response.data) {
        setEditErrMsg(`Edit course ${course.slug} failed with unknown error`)
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setEditErrMsg(`No courses were edited`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `Course ${course.title} was edited successfully!`,
        })
      )

      setCourse(response.data.course)

      setShowEditModal(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setEditErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setEditErrMsg("Data validation error")
        } else {
          setEditErrMsg(`Edit course ${course.slug} failed with unknown error`)
        }
      } else {
        setEditErrMsg(`Edit course ${course.slug} failed with unknown error`)
      }
      editErrRef.current?.focus()
    }
  }

  const editSections = async (json_string: string | undefined) => {
    if (!course) {
      setEditErrMsg(`No course to edit`)
      return
    }

    if (!json_string) {
      setEditSectionsErrMsg(`No sections json to edit`)
      return
    }

    try {
      let payload: AdminSectionSchema[] = JSON.parse(sectionsJSON)
      const response = await AdminAuthedApi.adminEditSectionsJson(
        course.slug,
        payload
      )
      if (!response.data) {
        setEditErrMsg(
          `Edit course ${course.slug} sections failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          children: `Course ${course.title} sections were edited successfully!`,
        })
      )

      setSections(response.data)
      setSectionsJSON(JSON.stringify(response.data, null, 2))

      setShowEditSectionsModal(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setEditSectionsErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setEditSectionsErrMsg("Data validation error")
        } else {
          setEditSectionsErrMsg(
            `Edit course ${course.slug} sections failed with unknown error`
          )
        }
      } else {
        setEditSectionsErrMsg(
          `Edit course ${course.slug} sections failed with unknown error`
        )
      }
      editSectionsErrRef.current?.focus()
    }
  }

  const calculateProgressCourse = (userCourse: AdminUserCourseSchema) => {
    if (
      userCourse.course.sections === null ||
      userCourse.course.sections === undefined ||
      userCourse.course.sections.length === 0
    ) {
      return 0
    }
    const lessonCount = userCourse.course.sections
      .map((s) => {
        return s.lessons.length
      })
      .reduce((prev, next) => prev + next)

    if (!userCourse.data || !(userCourse.data as any).sections) {
      return 0
    }

    const finishedLessonCount = Object.values((userCourse.data as any).sections)
      .map((s: any) => {
        return Object.values(s.lessons)
          .map((l: any) => {
            return l.completed ? 1 : (0 as number)
          })
          .reduce((prev, next) => prev + next)
      })
      .reduce((prev, next) => prev + next)

    return Math.round((finishedLessonCount / lessonCount) * 100)
  }

  const removeFromCourseUser = async (
    usercourse_association: AdminUserCourseSchema | undefined
  ) => {
    if (!usercourse_association) {
      setRemoveFromCourseErrMsg(
        `No user course association to remove from course`
      )
      return
    }

    if (!usercourse_associations) {
      setRemoveFromCourseErrMsg(`No userscourse associations`)
      return
    }

    try {
      const response = await AdminAuthedApi.adminRemoveUserFromCourse(
        usercourse_association.course.slug,
        usercourse_association.user.id
      )
      if (!response.data) {
        setRemoveFromCourseErrMsg(
          `Remove from course user ${usercourse_association.user.username} failed with unknown error`
        )
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setRemoveFromCourseErrMsg(`No users were removed from the course`)
        return
      }

      setToastList(
        toastList.concat({
          type: "success",
          children: `User ${usercourse_association.user.username} was remove from the coursed successfully!`,
        })
      )

      setUserCourseAssociations(response.data.user_course_associations)
      setShowRemoveFromCourseModal(false)
      setUserToRemoveFromCourse(undefined)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setRemoveFromCourseErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setRemoveFromCourseErrMsg("Data validation error")
        } else {
          setRemoveFromCourseErrMsg(
            `Removing user ${usercourse_association.user.username} from course failed with unknown error`
          )
        }
      } else {
        setRemoveFromCourseErrMsg(
          `Removing user ${usercourse_association.user.username} from course failed with unknown error`
        )
      }
      removeFromCourseErrRef.current?.focus()
    }
  }

  const columns = useMemo<ColumnDef<AdminUserCourseSchema>[]>(
    () => [
      {
        header: "USER ID",
        footer: (props) => props.column.id,
        accessorKey: "user.id",
        cell: (props) => (
          <span className="font-medium text-gray-900 dark:text-white">
            {props.row.original.user.id}
          </span>
        ),
      },
      {
        header: "USERNAME",
        footer: (props) => props.column.id,
        accessorKey: "user.username",
        cell: (props) => props.getValue(),
      },
      {
        header: "COMPLETION",
        footer: (props) => props.column.id,
        accessorKey: "data",
        cell: (props) => (
          <Progress
            progress={calculateProgressCourse(props.row.original)}
            color="green"
            labelProgress={true}
            progressLabelPosition="inside"
            theme={{
              base: "w-full overflow-hidden rounded-full bg-gray-200 dark:bg-neutral-600 h-2.5",
            }}
          />
        ),
        enableColumnFilter: false,
      },
      {
        id: "actions",
        footer: (props) => props.column.id,
        cell: (props) => (
          <div className="flex flex-row gap-1">
            <Tooltip content="Remove user from course">
              <button
                onClick={() => {
                  setShowRemoveFromCourseModal(true)
                  setUserToRemoveFromCourse(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <MdDelete size={20} />
              </button>
            </Tooltip>
          </div>
        ),
      },
    ],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [usercourse_associations]
  )

  return (
    <>
      <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center">
        <NavbarPrivate />
      </div>

      <AdminSidebar
        onCollapseChange={(collapsed) => {
          setSidebarCollapsed(collapsed)
        }}
      />
      <main
        className={
          sidebarCollapsed
            ? "pt-20 pl-0 transition-all ease-in-out duration-300"
            : "pt-20 sm:pl-64 lg:pl-96 transition-all ease-in-out duration-300"
        }
      >
        <div className="container mx-auto">
          <div className="p-5">
            <section id="courses">
              {course ? (
                <>
                  <h1 className="text-left text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mb-5 mx-3 md:mx-0">
                    {course.title}
                  </h1>
                  <div className="mb-2 block">
                    <Label htmlFor="id" value="ID" />
                  </div>
                  <TextInput
                    className="mb-2"
                    disabled
                    icon={FaHashtag}
                    readOnly
                    id="id"
                    placeholder="ID"
                    required
                    type="text"
                    value={course.id}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="slug" value="Slug" />
                  </div>
                  <TextInput
                    className="mb-2"
                    disabled
                    icon={BsCursorText}
                    readOnly
                    id="slug"
                    placeholder="Slug"
                    required
                    type="text"
                    value={course.slug}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="title" value="Title" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={MdTitle}
                    id="title"
                    placeholder="Title"
                    required
                    type="text"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="description" value="Description" />
                  </div>
                  <Textarea
                    className="mb-2"
                    id="description"
                    placeholder="Description"
                    required
                    value={description}
                    rows={5}
                    onChange={(e) => {
                      setDescription(e.target.value)
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="logo" value="Logo" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={AiFillPicture}
                    id="logo"
                    placeholder="Logo"
                    required
                    type="text"
                    value={logo}
                    onChange={(e) => {
                      setLogo(e.target.value)
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="syllabus" value="Syllabus JSON" />
                  </div>
                  <Textarea
                    className="mb-2"
                    id="syllabus"
                    placeholder="{}"
                    required
                    value={syllabus}
                    rows={15}
                    onChange={(e) => {
                      setSyllabus(e.target.value)
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="faqs" value="FAQs JSON" />
                  </div>
                  <Textarea
                    className="mb-2"
                    id="faqs"
                    placeholder="{}"
                    required
                    value={faqs}
                    rows={15}
                    onChange={(e) => {
                      setFaqs(e.target.value)
                    }}
                  />
                  <div className="flex items-center gap-2">
                    <Checkbox
                      id="hidden"
                      checked={hidden}
                      onChange={(e) => {
                        setHidden(e.target.checked)
                      }}
                    />
                    <Label htmlFor="hidden">Hidden</Label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Checkbox
                      id="prereleased"
                      checked={prereleased}
                      onChange={(e) => {
                        setPrereleased(e.target.checked)
                      }}
                    />
                    <Label htmlFor="prereleased">Pre-released</Label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Checkbox
                      id="hasExam"
                      checked={hasExam}
                      onChange={(e) => {
                        setHasExam(e.target.checked)
                      }}
                    />
                    <Label htmlFor="hasExam">HAs Exam</Label>
                  </div>
                  <div className="mb-2 block">
                    <Label htmlFor="release_date" value="Release date" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BsFillCalendarDateFill}
                    id="release_date"
                    placeholder="Release date"
                    required
                    type="text"
                    value={releaseDate}
                    onChange={(e) => {
                      setReleaseDate(e.target.value)
                    }}
                  />
                  <div className="mb-2 block">
                    <Label
                      htmlFor="discord_roles"
                      value="Discord Roles (Comma separated)"
                    />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={FaDiscord}
                    id="discord_roles"
                    placeholder="Discord Roles"
                    required
                    type="text"
                    value={discordRoles.join(",")}
                    onChange={(e) => {
                      setDiscordRoles(e.target.value.split(","))
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="promotion" value="Promotion" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BiSolidOffer}
                    id="promotion"
                    placeholder="Promotion"
                    required
                    type="text"
                    value={promotion}
                    onChange={(e) => {
                      setPromotion(parseInt(e.target.value))
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="promotion_code" value="Promotion Code" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BiBarcodeReader}
                    id="promotion_code"
                    placeholder="Promotion Code"
                    required
                    type="text"
                    value={promotionCode}
                    onChange={(e) => {
                      setPromotionCode(e.target.value)
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="max_sales" value="Max Sales" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BiArrowToTop}
                    id="max_sales"
                    placeholder="Max Sales"
                    required
                    type="text"
                    value={maxSales}
                    onChange={(e) => {
                      setMaxSales(parseInt(e.target.value))
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="sales_count" value="Sales Count" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={MdNumbers}
                    id="sales_count"
                    placeholder="Sales Count"
                    required
                    type="text"
                    value={salesCount}
                    onChange={(e) => {
                      setSalesCount(parseInt(e.target.value))
                    }}
                  />
                  <div className="mb-2 block">
                    <Label htmlFor="price" value="Price" />
                  </div>
                  <TextInput
                    className="mb-2"
                    icon={BiDollarCircle}
                    id="price"
                    placeholder="Price"
                    required
                    type="text"
                    value={price}
                    onChange={(e) => {
                      setPrice(parseInt(e.target.value))
                    }}
                  />
                  <div className="flex flex-row-reverse">
                    <Button
                      color="warning"
                      onClick={() => {
                        setShowEditModal(true)
                      }}
                    >
                      Edit course
                    </Button>
                  </div>
                  <Modal
                    size="md"
                    dismissible={true}
                    popup={true}
                    show={showEditModal}
                    onClose={() => {
                      setShowEditModal(false)
                    }}
                  >
                    <Modal.Header />
                    <Modal.Body>
                      <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          Are you sure you want to edit the course{" "}
                          {course?.slug}?
                        </h3>
                        <div className={!editErrMsg ? "sr-only" : ""}>
                          <Alert
                            color="failure"
                            icon={ExclamationTriangleIcon}
                            onDismiss={function onDismiss() {
                              setEditErrMsg("")
                            }}
                          >
                            <span ref={editErrRef}>{editErrMsg}</span>
                          </Alert>
                        </div>
                        <div className="flex justify-center gap-4">
                          <Button
                            color="failure"
                            onClick={() => editCourse(course)}
                          >
                            Yes, I'm sure
                          </Button>
                          <Button
                            color="gray"
                            onClick={() => setShowEditModal(false)}
                          >
                            No, cancel
                          </Button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <h3 className="text-left text-lg md:text-xl font-bold text-gray-800 dark:text-white mt-5 mb-5 mx-3 md:mx-0">
                    Sections
                  </h3>
                  <Tabs aria-label="Section tabs">
                    <Tabs.Item active icon={BiSolidDashboard} title="UI">
                      <>
                        <Accordion collapseAll>
                          {sections.map((section) => (
                            <Accordion.Panel key={section.id}>
                              <Accordion.Title>{section.title}</Accordion.Title>
                              <Accordion.Content>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>ID: </>
                                  <>{section.id}</>
                                </div>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>Index: </>
                                  <>{section.index}</>
                                </div>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>Slug: </>
                                  <>{section.slug}</>
                                </div>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>Title: </>
                                  <>{section.title}</>
                                </div>
                                <div className="flex text-gray-800 dark:text-white text-sm">
                                  <>Description: </>
                                  <>{section.description}</>
                                </div>
                                <Accordion collapseAll className="mt-5">
                                  {section.lessons.map((lesson) => (
                                    <Accordion.Panel key={lesson.id}>
                                      <Accordion.Title>
                                        {lesson.title}
                                      </Accordion.Title>
                                      <Accordion.Content>
                                        <div className="flex text-gray-800 dark:text-white text-sm">
                                          <>ID: </>
                                          <>{lesson.id}</>
                                        </div>
                                        <div className="flex text-gray-800 dark:text-white text-sm">
                                          <>Index: </>
                                          <>{lesson.index}</>
                                        </div>
                                        <div className="flex text-gray-800 dark:text-white text-sm">
                                          <>Slug: </>
                                          <>{lesson.slug}</>
                                        </div>
                                        <div className="flex text-gray-800 dark:text-white text-sm">
                                          <>Title: </>
                                          <>{lesson.title}</>
                                        </div>
                                        <div className="flex text-gray-800 dark:text-white text-sm">
                                          <>Description: </>
                                          <>{lesson.description}</>
                                        </div>
                                        <div className="text-gray-800 dark:text-white text-sm">
                                          <>Flags: </>
                                          <ul className="list-disc list-inside">
                                            {lesson.flags.map((flag) => (
                                              <li key={flag.id}>
                                                ID: {flag.id}, Index:{" "}
                                                {flag.index}, Title:{" "}
                                                {flag.title}, Description:{" "}
                                                {flag.description}, Value:{" "}
                                                {flag.value}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </Accordion.Content>
                                    </Accordion.Panel>
                                  ))}
                                </Accordion>
                              </Accordion.Content>
                            </Accordion.Panel>
                          ))}
                        </Accordion>
                      </>
                    </Tabs.Item>
                    <Tabs.Item icon={VscJson} title="JSON">
                      <div className="mb-2 block">
                        <Label htmlFor="sectionsJSON" value="Sections JSON" />
                      </div>
                      <Textarea
                        className="mb-2"
                        id="sectionsJSON"
                        placeholder="[]"
                        required
                        value={sectionsJSON}
                        rows={50}
                        onChange={(e) => {
                          setSectionsJSON(e.target.value)
                        }}
                      />
                      <div className="flex flex-row-reverse">
                        <Button
                          color="warning"
                          onClick={() => {
                            setShowEditSectionsModal(true)
                          }}
                        >
                          Edit sections
                        </Button>
                      </div>
                      <Modal
                        size="md"
                        dismissible={true}
                        popup={true}
                        show={showEditSectionsModal}
                        onClose={() => {
                          setShowEditSectionsModal(false)
                        }}
                      >
                        <Modal.Header />
                        <Modal.Body>
                          <div className="text-center">
                            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                              Are you sure you want to edit the sections of
                              course {course?.slug}?
                            </h3>
                            <div
                              className={!editSectionsErrMsg ? "sr-only" : ""}
                            >
                              <Alert
                                color="failure"
                                icon={ExclamationTriangleIcon}
                                onDismiss={function onDismiss() {
                                  setEditSectionsErrMsg("")
                                }}
                              >
                                <span ref={editSectionsErrRef}>
                                  {editSectionsErrMsg}
                                </span>
                              </Alert>
                            </div>
                            <div className="flex justify-center gap-4">
                              <Button
                                color="failure"
                                onClick={() => editSections(sectionsJSON)}
                              >
                                Yes, I'm sure
                              </Button>
                              <Button
                                color="gray"
                                onClick={() => setShowEditSectionsModal(false)}
                              >
                                No, cancel
                              </Button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </Tabs.Item>
                  </Tabs>
                  <h3 className="text-left text-lg md:text-xl font-bold text-gray-800 dark:text-white mt-5 mb-5 mx-3 md:mx-0">
                    Users enrolled
                  </h3>
                  <Modal
                    size="md"
                    dismissible={true}
                    popup={true}
                    show={showRemoveFromCourseModal}
                    onClose={() => {
                      setShowRemoveFromCourseModal(false)
                      setUserToRemoveFromCourse(undefined)
                    }}
                  >
                    <Modal.Header />
                    <Modal.Body>
                      <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                          Are you sure you want to remove user{" "}
                          {userToRemoveFromCourse?.user.username} from the
                          course?
                        </h3>
                        <div
                          className={!removeFromCourseErrMsg ? "sr-only" : ""}
                        >
                          <Alert
                            color="failure"
                            icon={ExclamationTriangleIcon}
                            onDismiss={function onDismiss() {
                              setRemoveFromCourseErrMsg("")
                            }}
                          >
                            <span ref={removeFromCourseErrRef}>
                              {removeFromCourseErrMsg}
                            </span>
                          </Alert>
                        </div>
                        <Alert
                          className="mb-4"
                          color="warning"
                          icon={ExclamationTriangleIcon}
                        >
                          <span>
                            This will remove the user's association to the
                            course including progress, the user's association
                            with the lab and all lab flags and the user's exam
                            association and all the lab flags.
                          </span>
                        </Alert>
                        <div className="flex justify-center gap-4">
                          <Button
                            color="failure"
                            onClick={() =>
                              removeFromCourseUser(userToRemoveFromCourse)
                            }
                          >
                            Yes, I'm sure
                          </Button>
                          <Button
                            color="gray"
                            onClick={() => setShowRemoveFromCourseModal(false)}
                          >
                            No, cancel
                          </Button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <div className="mb-10">
                    {usercourse_associations?.length ? (
                      <AdminTable
                        columns={columns}
                        data={usercourse_associations}
                      />
                    ) : (
                      <Alert color="warning" icon={ExclamationTriangleIcon}>
                        <span>No users enrolled</span>
                      </Alert>
                    )}
                  </div>
                </>
              ) : (
                <Alert color="warning" icon={ExclamationTriangleIcon}>
                  <span>No course to display</span>
                </Alert>
              )}

              <div className={!errMsg ? "sr-only" : ""}>
                <Alert
                  color="failure"
                  icon={ExclamationTriangleIcon}
                  onDismiss={function onDismiss() {
                    setErrMsg("")
                  }}
                >
                  <span ref={errRef}>{errMsg}</span>
                </Alert>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  )
}

export default AdminCourse
