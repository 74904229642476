// import { Link } from "react-router-dom"
import { BannerProps } from "../components/Banner"
import { createContext, PropsWithChildren, useState } from "react"

export type BannerListData = BannerProps[]

interface BannersContextProps {
  bannerList: BannerListData
  setBannerList: (bannerList: BannerListData) => void
}

const BannersContext = createContext<BannersContextProps>({
  bannerList: [],
  setBannerList: () => {},
})

export const BannersProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [bannerList, setBannerList] = useState<BannerListData>([
    // {
    //   id: "arte_lab_extension_28_04_24",
    //   children: (
    //     <div className="flex flex-col">
    //       <strong className="text-lg">ARTE Lab Extension</strong>
    //       Following some efficiency improvements in our lab system we will now
    //       be offering 60 days of lab access with the ARTE certification voucher!
    //       We have extended all active ARTE labs by 15 days and all ARTE vouchers
    //       activated from now on will include the 60 days even if purchased in
    //       the past.
    //       <br></br>
    //       More info on how to use the new lab system can be found in lesson{" "}
    //       <Link
    //         to="https://training.hacktricks.xyz/classroom/arte#0_arte_presentation/0_2_labs_presentation"
    //         className="font-medium text-red-logo hover:text-red-900 text-lg"
    //       >
    //         0.2 Lab Presentation
    //       </Link>
    //     </div>
    //   ),
    // },
  ])
  return (
    <BannersContext.Provider value={{ bannerList, setBannerList }}>
      {children}
    </BannersContext.Provider>
  )
}

export default BannersContext
