import { Sidebar, Spinner, Tooltip } from "flowbite-react"
import { BiCircle, BiCheckCircle } from "react-icons/bi"
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarLeftExpand,
} from "react-icons/tb"
import { VscDebugStart } from "react-icons/vsc"
import { Link, useLocation } from "react-router-dom"
import { FC, useEffect, useState } from "react"
import { PublicCourseSchema, UserCourseSchema, UserLabSchema } from "../../api"

export type ClassroomSidebarProps = {
  course?: PublicCourseSchema | undefined
  courseData?: UserCourseSchema | undefined
  labData?: UserLabSchema | undefined
  onCollapseChange: (collapsed: boolean) => void
}

const ClassroomSidebar: FC<ClassroomSidebarProps> = ({
  course,
  courseData,
  labData,
  onCollapseChange,
}) => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < 640)
  const { hash } = useLocation()
  const [currentSection, setCurrentSection] = useState("")
  const [currentLesson, setCurrentLesson] = useState("")

  const toogleSidebar = () => {
    onCollapseChange(!collapsed)
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    if (hash) {
      let hash_trimmed = hash.substring(1)
      setCurrentSection(hash_trimmed.split("/")[0])
      setCurrentLesson(hash_trimmed.split("/")[1])
    }

    function handleResize() {
      const { innerWidth: width } = window
      if (width < 640) {
        setCollapsed(true)
      }
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash])

  return (
    <>
      <Sidebar
        className={
          "z-30 mt-20 pb-20 w-64 lg:w-96 fixed inset-y-0 overflow-x-hidden overflow-y-auto block !bg-neutral-300 dark:!bg-neutral-900 transition-all ease-in-out duration-300" +
          (collapsed ? " -translate-x-full" : " translate-x-0")
        }
        theme={{
          root: {
            base: "",
            inner: "",
          },
          item: {
            base: "",
            collapsed: {
              insideCollapse: "",
            },
            content: {
              base: "",
            },
          },
        }}
        aria-label="Sidebar"
      >
        <div className="p-4">
          <Sidebar.Items>
            {course !== undefined &&
            courseData !== undefined &&
            labData !== undefined ? (
              <>
                <h3 className="text-neutral-900 dark:text-white font-bold">
                  {course.title}
                </h3>
                <Sidebar.ItemGroup className="mt-4 space-y-2 border-t-2 pt-4 first:mt-0 first:border-t-0 first:pt-0 border-neutral-700 dark:border-neutral-700">
                  {course.sections === undefined || course.sections === null ? (
                    <></>
                  ) : (
                    <>
                      {course.sections.map((section: any) => (
                        <Sidebar.Collapse
                          theme={{
                            button:
                              "group flex w-full items-center p-2 font-normal hover:bg-neutral-700 dark:hover:bg-neutral-700 !text-neutral-800 transition duration-75 group-hover:text-white dark:!text-neutral-400 dark:group-hover:text-white dark:hover:!text-white hover:!text-white rounded-none",
                            icon: {
                              base:
                                (courseData.data as any).sections &&
                                (courseData.data as any).sections[section.id] &&
                                (courseData.data as any).sections[section.id]
                                  .completed
                                  ? "h-5 w-5 transition duration-75 group-hover:text-green-300 text-green-400"
                                  : "h-5 w-5 transition duration-75 group-hover:text-white text-neutral-800 dark:text-neutral-400",
                              open: {
                                off: "",
                                on: "",
                              },
                            },
                          }}
                          open={currentSection === section.slug}
                          key={section.id}
                          icon={
                            (courseData.data as any).sections &&
                            (courseData.data as any).sections[section.id] &&
                            (courseData.data as any).sections[section.id]
                              .completed
                              ? BiCheckCircle
                              : BiCircle
                          }
                          label={section.title}
                        >
                          {section.lessons.map((lesson: any) => (
                            <Sidebar.Item
                              theme={{
                                icon: {
                                  base:
                                    (courseData.data as any).sections &&
                                    (courseData.data as any).sections[
                                      section.id
                                    ] &&
                                    (courseData.data as any).sections[
                                      section.id
                                    ].lessons &&
                                    (courseData.data as any).sections[
                                      section.id
                                    ].lessons[lesson.id] &&
                                    (courseData.data as any).sections[
                                      section.id
                                    ].lessons[lesson.id].completed
                                      ? "h-5 w-5 transition duration-75 group-hover:!text-green-300 !text-green-400"
                                      : "h-5 w-5 transition duration-75 group-hover:!text-white !text-neutral-800 dark:!text-neutral-400",
                                },
                                active: "bg-neutral-700 !text-white",
                              }}
                              key={lesson.id}
                              as={Link}
                              to={`#${section.slug}/${lesson.slug}`}
                              active={currentLesson === lesson.slug}
                              icon={
                                (courseData.data as any).sections &&
                                (courseData.data as any).sections[section.id] &&
                                (courseData.data as any).sections[section.id]
                                  .lessons &&
                                (courseData.data as any).sections[section.id]
                                  .lessons[lesson.id] &&
                                (courseData.data as any).sections[section.id]
                                  .lessons[lesson.id].completed
                                  ? BiCheckCircle
                                  : BiCircle
                              }
                              className="ml-5 flex items-start justify-start p-2 font-normal hover:bg-neutral-700 dark:hover:bg-neutral-700 !text-neutral-800 dark:!text-neutral-400 transition duration-75 group-hover:text-white dark:group-hover:text-white dark:hover:!text-white hover:!text-white rounded-none"
                            >
                              <div className="whitespace-normal ml-3 align-middle">
                                {lesson.title}
                                {(labData?.data as any).lessons_state &&
                                (labData?.data as any).lessons_state[
                                  lesson.id
                                ] &&
                                ((labData?.data as any).lessons_state[lesson.id]
                                  .state === "PROVISIONED" ||
                                  (labData?.data as any).lessons_state[
                                    lesson.id
                                  ].state === "PROVISIONING") ? (
                                  <Tooltip
                                    content="Lab deployed"
                                    placement="top"
                                    theme={{
                                      target: "my-auto inline",
                                    }}
                                    className="font-sans inline"
                                  >
                                    <VscDebugStart
                                      className="inline mb-1 ml-1"
                                      color="#e3a008"
                                    />
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </Sidebar.Item>
                          ))}
                        </Sidebar.Collapse>
                      ))}
                    </>
                  )}
                </Sidebar.ItemGroup>
              </>
            ) : (
              <div className="flex justify-items-center">
                <div className="justify-self-center grow">
                  <Spinner
                    size="xl"
                    color="failure"
                    theme={{
                      base: "mx-auto animate-spin text-gray-200 fill-red-600 dark:text-gray-600 w-10 h-10",
                    }}
                  />
                </div>
              </div>
            )}
          </Sidebar.Items>
        </div>
      </Sidebar>
      <button
        onClick={() => {
          toogleSidebar()
        }}
        className="p-0 w-10 h-10 bg-red-logo rounded-full hover:bg-red-900 active:shadow-lg mouse shadow transition ease-in duration-50 focus:outline-none z-40 fixed bottom-7 left-7 flex items-center"
      >
        {collapsed ? (
          <TbLayoutSidebarLeftExpand
            size="1.7em"
            color="white"
            className="grow justify-self-center"
          />
        ) : (
          <TbLayoutSidebarLeftCollapse
            size="1.7em"
            color="white"
            className="grow justify-self-center"
          />
        )}
      </button>
    </>
  )
}

export default ClassroomSidebar
