import {
  Alert,
  Button,
  Checkbox,
  Label,
  Modal,
  Radio,
  TextInput,
  Tooltip,
} from "flowbite-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import AdminSidebar from "../../components/sidebar/AdminSidebar"
import useAuthedApi from "../../hooks/useAuthedApi"
import useToasts from "../../hooks/useToasts"
import { isAxiosError } from "axios"
import { ColumnDef } from "@tanstack/react-table"
import { RiEdit2Fill } from "react-icons/ri"
import { MdDelete } from "react-icons/md"
import AdminTable from "../../components/tables/AdminTable"
import { AdminVoucherSchema } from "../../api"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"

const AdminVouchers = () => {
  const [vouchers, setVouchers] = useState<AdminVoucherSchema[]>()
  const { AdminAuthedApi } = useAuthedApi()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const [openCreateVoucherModal, setOpenCreateVoucherModal] = useState(false)
  const [createVoucherErrMsg, setCreateVoucherErrMsg] = useState("")
  const [createVoucherType, setCreateVoucherType] = useState("COURSE")
  const [createVoucherCourse, setCreateVoucherCourse] = useState("")
  const [createVoucherLab, setCreateVoucherLab] = useState("")
  const [createVoucherLabDuration, setCreateVoucherLabDuration] = useState(30)
  const [createVoucherExam, setCreateVoucherExam] = useState("")
  const [createVoucherUser, setCreateVoucherUser] = useState("")
  const createVoucherErrRef = useRef<HTMLInputElement>(null)
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    window.innerWidth < 640
  )

  const { toastList, setToastList } = useToasts()

  const getVouchers = async () => {
    try {
      const response = await AdminAuthedApi.adminReadVouchers()
      if (!response.data) {
        setErrMsg("Get vouchers failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setVouchers(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get Vouchers failed with unknown error")
        }
      } else {
        setErrMsg("Get Vouchers failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const createVoucher = async () => {
    try {
      let response
      if (createVoucherType === "COURSE") {
        response = await AdminAuthedApi.adminCreateCourseVoucher({
          course_id: createVoucherCourse,
          user_id: createVoucherUser,
        })
      } else if (createVoucherType === "LAB") {
        response = await AdminAuthedApi.adminCreateLabVoucher({
          lab_id: createVoucherLab,
          user_id: createVoucherUser,
          duration: createVoucherLabDuration,
        })
      } else if (createVoucherType === "EXAM") {
        response = await AdminAuthedApi.adminCreateExamVoucher({
          exam_id: createVoucherExam,
          user_id: createVoucherUser,
        })
      }
      if (!response?.data) {
        setErrMsg("Create voucher failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          children: `New voucher sent!`,
        })
      )
      setOpenCreateVoucherModal(false)
      setCreateVoucherCourse("")
      setCreateVoucherLab("")
      setCreateVoucherExam("")
      setCreateVoucherUser("")
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setCreateVoucherErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setCreateVoucherErrMsg("Data validation error")
        } else {
          setCreateVoucherErrMsg("Create Voucher failed with unknown error")
        }
      } else {
        setCreateVoucherErrMsg("Create Voucher failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  useEffect(() => {
    getVouchers()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo<ColumnDef<AdminVoucherSchema>[]>(
    () => [
      {
        header: "VOUCHER ID",
        footer: (props) => props.column.id,
        accessorKey: "id",
        cell: (props) => (
          <span className="font-medium text-gray-900 dark:text-white">
            {props.row.original.id}
          </span>
        ),
      },
      {
        header: "TYPE",
        footer: (props) => props.column.id,
        accessorKey: "type",
        cell: (props) => props.getValue(),
      },
      {
        header: "USER",
        footer: (props) => props.column.id,
        accessorKey: "user.username",
        cell: (props) => props.getValue(),
      },
      {
        header: "USER EMAIL",
        footer: (props) => props.column.id,
        accessorKey: "user.email",
        cell: (props) => props.getValue(),
      },
      {
        header: "COURSE",
        footer: (props) => props.column.id,
        accessorKey: "course.slug",
        cell: (props) => props.getValue(),
      },
      {
        header: "IS USED",
        footer: (props) => props.column.id,
        accessorKey: "is_used",
        cell: (props) => (
          <Checkbox checked={props.getValue() ? true : false} disabled={true} />
        ),
        enableColumnFilter: false,
      },
      {
        header: "CREATED AT",
        footer: (props) => props.column.id,
        accessorKey: "created_at",
        cell: (props) => props.getValue(),
      },
      {
        header: "REDEEMED AT",
        footer: (props) => props.column.id,
        accessorKey: "redeemed_at",
        cell: (props) => props.getValue(),
      },
      {
        id: "actions",
        footer: (props) => props.column.id,
        cell: (props) => (
          <div className="flex flex-row gap-1">
            <Tooltip content="Edit Voucher">
              <button
                onClick={() => {
                  // setShowEditModal(true)
                  // setAWSAccountToEdit(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <RiEdit2Fill size={20} />
              </button>
            </Tooltip>
            <Tooltip content="Delete Voucher">
              <button
                onClick={() => {
                  // setShowDeleteModal(true)
                  // setAWSAccountToDelete(props.row.original)
                }}
                className="font-medium text-center text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
              >
                <MdDelete size={20} />
              </button>
            </Tooltip>
          </div>
        ),
      },
    ],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [vouchers]
  )

  return (
    <>
      <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center">
        <NavbarPrivate />
      </div>

      <AdminSidebar
        onCollapseChange={(collapsed) => {
          setSidebarCollapsed(collapsed)
        }}
      />
      <main
        className={
          sidebarCollapsed
            ? "pt-20 pl-0 transition-all ease-in-out duration-300"
            : "pt-20 sm:pl-64 lg:pl-96 transition-all ease-in-out duration-300"
        }
      >
        <div className="container mx-auto">
          <div className="p-5">
            <section id="vouchers">
              <Modal
                show={openCreateVoucherModal}
                dismissible
                size="2xl"
                onClose={() => setOpenCreateVoucherModal(false)}
                theme={{
                  content: {
                    inner:
                      "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
                  },
                }}
              >
                <Modal.Header>Create a new voucher</Modal.Header>
                <Modal.Body>
                  <div className="text-center">
                    <Alert
                      className="mb-4"
                      color="warning"
                      icon={ExclamationTriangleIcon}
                    >
                      <span>
                        An email with the voucher will be sent to the user
                      </span>
                    </Alert>

                    <fieldset className="flex flex-row gap-4 mx-5 mb-5">
                      <legend className="text-left mb-4 text-gray-800 dark:text-white">
                        Voucher Type
                      </legend>
                      <div className="flex items-center gap-2">
                        <Radio
                          id="course"
                          name="type"
                          value="COURSE"
                          defaultChecked
                          onChange={(e) => {
                            if (e.target.value === "COURSE") {
                              setCreateVoucherType("COURSE")
                            }
                          }}
                        />
                        <Label htmlFor="course">Course</Label>
                      </div>
                      <div className="flex items-center gap-2">
                        <Radio
                          id="lab"
                          name="type"
                          value="LAB"
                          onChange={(e) => {
                            if (e.target.value === "LAB") {
                              setCreateVoucherType("LAB")
                            }
                          }}
                        />
                        <Label htmlFor="lab">Lab</Label>
                      </div>
                      <div className="flex items-center gap-2">
                        <Radio
                          id="exam"
                          name="type"
                          value="EXAM"
                          onChange={(e) => {
                            if (e.target.value === "EXAM") {
                              setCreateVoucherType("EXAM")
                            }
                          }}
                        />
                        <Label htmlFor="exam">Exam</Label>
                      </div>
                    </fieldset>
                    <>
                      {createVoucherType === "COURSE" ? (
                        <>
                          <div className="max-w-md mb-4 mx-auto" id="select">
                            <div className="mb-2 block text-start">
                              <Label htmlFor="course_id" value="Course ID" />
                            </div>
                            <TextInput
                              id="course_id"
                              type="text"
                              placeholder="Course ID"
                              required={true}
                              onChange={(e) => {
                                setCreateVoucherCourse(e.target.value)
                              }}
                              value={createVoucherCourse}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                    <>
                      {createVoucherType === "LAB" ? (
                        <>
                          <div className="max-w-md mb-4 mx-auto">
                            <div className="mb-2 block text-start">
                              <Label htmlFor="lab_id" value="Lab ID" />
                            </div>
                            <TextInput
                              id="lab_id"
                              type="text"
                              placeholder="Lab ID"
                              required={true}
                              onChange={(e) => {
                                setCreateVoucherLab(e.target.value)
                              }}
                              value={createVoucherLab}
                            />
                          </div>
                          <div className="max-w-md mb-4 mx-auto">
                            <div className="mb-2 block text-start">
                              <Label
                                htmlFor="lab_duration"
                                value="Lab Duration"
                              />
                            </div>
                            <TextInput
                              id="lab_duration"
                              type="number"
                              placeholder="Lab Duration"
                              required={true}
                              onChange={(e) => {
                                setCreateVoucherLabDuration(
                                  parseInt(e.target.value)
                                )
                              }}
                              value={createVoucherLabDuration}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>

                    <>
                      {createVoucherType === "EXAM" ? (
                        <>
                          <div className="max-w-md mb-4 mx-auto" id="select">
                            <div className="mb-2 block text-start">
                              <Label htmlFor="exam_id" value="Exam ID" />
                            </div>
                            <TextInput
                              id="exam_id"
                              type="text"
                              placeholder="Exam ID"
                              required={true}
                              onChange={(e) => {
                                setCreateVoucherExam(e.target.value)
                              }}
                              value={createVoucherExam}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>

                    <div className="max-w-md mb-4 mx-auto" id="select">
                      <div className="mb-2 block text-start">
                        <Label htmlFor="user_id" value="User ID" />
                      </div>
                      <TextInput
                        id="user_id"
                        type="text"
                        placeholder="User ID"
                        required={true}
                        onChange={(e) => {
                          setCreateVoucherUser(e.target.value)
                        }}
                        value={createVoucherUser}
                      />
                    </div>
                    <div className={!createVoucherErrMsg ? "sr-only" : ""}>
                      <Alert
                        color="failure"
                        icon={ExclamationTriangleIcon}
                        onDismiss={function onDismiss() {
                          setCreateVoucherErrMsg("")
                        }}
                      >
                        <span ref={createVoucherErrRef}>
                          {createVoucherErrMsg}
                        </span>
                      </Alert>
                    </div>
                    <div className="flex justify-center gap-4 mt-4">
                      <Button color="failure" onClick={() => createVoucher()}>
                        Create voucher
                      </Button>
                      <Button
                        color="gray"
                        onClick={() => setOpenCreateVoucherModal(false)}
                      >
                        No, cancel
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <div className="flex">
                <h1 className="text-left text-2xl md:text-4xl font-bold text-gray-800 dark:text-white mb-5 mx-3 md:mx-0">
                  Vouchers
                </h1>
                <div className="grow flex flex-row-reverse md:text-4xl mt-24 mb-5 mx-3 md:mx-0">
                  <Button
                    type="button"
                    onClick={() => {
                      setOpenCreateVoucherModal(true)
                    }}
                    className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                  >
                    Create Voucher
                  </Button>
                </div>
              </div>
              <div className={!errMsg ? "sr-only" : ""}>
                <Alert
                  color="failure"
                  icon={ExclamationTriangleIcon}
                  onDismiss={function onDismiss() {
                    setErrMsg("")
                  }}
                >
                  <span ref={errRef}>{errMsg}</span>
                </Alert>
              </div>
              <div className="mb-10">
                {vouchers?.length ? (
                  <AdminTable columns={columns} data={vouchers} />
                ) : (
                  <Alert color="warning" icon={ExclamationTriangleIcon}>
                    <span>No vouchers to display</span>
                  </Alert>
                )}
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  )
}

export default AdminVouchers
