import { useRef, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { isAxiosError } from "axios"
import useApi from "../../hooks/useApi"
import { Alert, Button, Label, TextInput } from "flowbite-react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import useToasts from "../../hooks/useToasts"
import HT_TRAINING_logo from "../../assets/images/HT_TRAINING_logo.svg"

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/

const ForgotPassword = () => {
  const { toastList, setToastList } = useToasts()
  const { AuthApi } = useApi()

  const emailRef = useRef<HTMLInputElement>(null)
  const errRef = useRef<HTMLInputElement>(null)
  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [errMsg, setErrMsg] = useState("")

  useEffect(() => {
    emailRef.current?.focus()
  }, [])

  useEffect(() => {
    setErrMsg("")
  }, [email])

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email))
  }, [email])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // if button enabled with JS hack
    if (!EMAIL_REGEX.test(email)) {
      setErrMsg("Invalid data")
      return
    }

    try {
      const response = await AuthApi.authForgotPassword({ email: email })
      if (!response.data.success) {
        setErrMsg("Forgot password failed with unknown error")
        return
      }
      //   console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          children: "Password reset email sent successfully!",
        })
      )
      setEmail("")
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Forgot password failed with unknown error")
        }
      } else {
        setErrMsg("Forgot password failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  return (
    <section>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-48 w-auto"
              src={HT_TRAINING_logo}
              alt="HackTricks Training"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
              Forgot your password?
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600 dark:text-white">
              Or{" "}
              <Link
                to="/signin"
                className="font-medium text-red-logo hover:text-red-900"
              >
                go back to sign in!
              </Link>
            </p>
          </div>
          <div className={!errMsg ? "sr-only" : ""}>
            <Alert
              color="failure"
              icon={ExclamationTriangleIcon}
              onDismiss={function onDismiss() {
                setErrMsg("")
              }}
            >
              <span ref={errRef}>{errMsg}</span>
            </Alert>
          </div>
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email" value="Email" />
              </div>
              <TextInput
                id="email"
                type="email"
                placeholder="Email"
                autoComplete="email"
                required={true}
                ref={emailRef}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                value={email}
                aria-invalid={validEmail ? "false" : "true"}
                color={validEmail ? "success" : !email ? "" : "failure"}
                helperText={
                  <>
                    <span
                      className={
                        emailFocus && email && !validEmail ? "" : "sr-only"
                      }
                    >
                      <ExclamationTriangleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                      Invalid email:
                      <br />
                      Must be a valid email
                    </span>
                  </>
                }
              />
            </div>

            <div className="text-sm">
              <p className="font-medium text-gray-600 dark:text-gray-200">
                We will email you instructions to reset your password
              </p>
            </div>
            <div className="flex flex-col items-center">
              <Button
                disabled={!validEmail ? true : false}
                className="w-full md:w-auto px-20 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ForgotPassword
