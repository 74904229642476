import { Accordion, Timeline } from "flowbite-react"
import React, { FC } from "react"
import { FaFlask, FaVideo } from "react-icons/fa"
import { IoIosFolderOpen } from "react-icons/io"

interface AnyObject {
  [key: string]: any
}

export type SyllabusProps = {
  syllabus?: AnyObject | undefined
}

const Syllabus: FC<SyllabusProps> = ({ syllabus }) => {
  return (
    <div className="mt-7 md:mt-14 justify-center dark:text-white w-auto sm:w-auto md:w-2/3 lg:w-1/2 xl:w-1/2 mx-auto mb-5">
      <Accordion
        collapseAll={true}
        alwaysOpen={true}
        flush={true}
        className="divide-y !divide-gray-500 !border-gray-500 dark:!divide-gray-200 dark:!border-gray-200 border-b"
      >
        <Accordion.Panel>
          <Accordion.Title
            theme={{
              heading: "text-xl font-bold",
              base: "flex w-full justify-center items-center first:rounded-t-lg last:rounded-b-lg py-5 px-5 text-center font-medium !bg-transparent dark:!bg-transparent dark:text-white hover:text-gray-700 hover:dark:text-gray-200",
              // arrow: { base: "" },
            }}
          >
            SYLLABUS
          </Accordion.Title>
          <Accordion.Content
            theme={{
              base: "py-5 px-5 !bg-transparent dark:!bg-transparent",
            }}
          >
            {syllabus ? (
              <Timeline
                theme={{
                  root: {
                    direction: {
                      vertical:
                        "relative border-l border-gray-500 dark:border-gray-200",
                    },
                  },
                }}
              >
                {syllabus.sections.map((section: any) => (
                  <Timeline.Item
                    key={parseInt(section.index)}
                    theme={{
                      root: {
                        vertical: "mb-5 ml-6 last:mb-0",
                      },
                    }}
                  >
                    <Timeline.Point
                      icon={IoIosFolderOpen}
                      theme={{
                        marker: {
                          icon: {
                            wrapper:
                              "absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-blue-200 ring-0 dark:bg-blue-900",
                          },
                        },
                      }}
                    />
                    <Timeline.Content>
                      <Timeline.Title>{section.title}</Timeline.Title>
                      {section.chapters.length ? (
                        <Timeline.Body>
                          <Timeline
                            className="mt-3"
                            theme={{
                              root: {
                                direction: {
                                  vertical:
                                    "relative border-l border-gray-500 dark:border-gray-200",
                                },
                              },
                            }}
                          >
                            {section.chapters.map((chapter: any) => (
                              <Timeline.Item
                                key={
                                  parseInt(section.index) +
                                  "." +
                                  parseInt(chapter.index)
                                }
                                theme={{
                                  root: {
                                    vertical: "mb-5 ml-6 last:mb-0",
                                  },
                                }}
                              >
                                <Timeline.Point
                                  icon={FaVideo}
                                  theme={{
                                    marker: {
                                      icon: {
                                        wrapper:
                                          "absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-blue-200 ring-0 dark:bg-blue-900",
                                      },
                                    },
                                  }}
                                />
                                <Timeline.Content>
                                  <Timeline.Title>
                                    <div className="flex flex-row">
                                      <span className="mr-3 text-sm">
                                        {chapter.title}
                                      </span>
                                      {chapter.labs ? (
                                        <span className="flex h-6 w-6 items-center justify-center rounded-full bg-amber-500 ring-0 dark:bg-amber-800">
                                          <FaFlask className="h-3 w-3 text-amber-800 dark:text-amber-500"></FaFlask>
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </Timeline.Title>
                                </Timeline.Content>
                              </Timeline.Item>
                            ))}
                          </Timeline>
                        </Timeline.Body>
                      ) : (
                        <></>
                      )}
                    </Timeline.Content>
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <p>No syllabus just yet</p>
            )}
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  )
}

export default Syllabus
