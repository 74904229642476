import { NavbarLink } from "flowbite-react"
import { Link } from "react-router-dom"
import NavbarCoursesDropdown from "./NavbarCoursesDropdown"
import NavbarCustom from "./NavbarCustom"

const NavbarPublic = () => {
  return (
    <NavbarCustom>
      <NavbarLink
        as={Link}
        to="/"
        theme={{
          base: "block py-2 pr-4 pl-3 md:p-0 text-neutral-900 dark:text-white hover:text-neutral-400 cursor-pointer font-bold uppercase font-hacktricks",
          active: {
            off: "text-neutral-900 dark:text-white hover:text-neutral-400",
            on: "text-neutral-900 dark:text-white",
          },
        }}
      >
        Home
      </NavbarLink>
      <NavbarCoursesDropdown />
    </NavbarCustom>
  )
}

export default NavbarPublic
