import Footer from "../../components/Footer"
import NavbarCustom from "../../components/navbar/NavbarCustom"

import { NavbarLink } from "../../components/navbar/NavbarLinkScroll"
import useApi from "../../hooks/useApi"
import { isAxiosError } from "axios"
import { PublicCourseSchema } from "../../api"
import { useEffect, useState } from "react"
import CustomCarousel from "../../components/CustomCarousel"
import ARTE_CAN from "../../assets/images/ARTE_CAN.png"
import ARTA_CAN from "../../assets/images/ARTA_CAN.png"
import GRTE_CAN from "../../assets/images/GRTE_CAN.png"
import GRTA_CAN from "../../assets/images/GRTA_CAN.png"
import AzRTE_CAN from "../../assets/images/AzRTE_CAN.png"
import AzRTA_CAN from "../../assets/images/AzRTA_CAN.png"
import expert_instructors from "../../assets/images/expert_instructors.png"
import hacktricks_based from "../../assets/images/hacktricks_based.png"
import hands_on from "../../assets/images/hands_on.png"

import Loading from "../../components/Loading"
import { Alert, Button, Modal } from "flowbite-react"
import { Link } from "react-router-dom"
import ModalBuyCourse from "../../components/modals/ModalBuyCourse"
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import useAuth from "../../hooks/useAuth"
import useAuthedApi from "../../hooks/useAuthedApi"
import NavbarCoursesDropdown from "../../components/navbar/NavbarCoursesDropdown"

const Landing = () => {
  const [arte, setArte] = useState<PublicCourseSchema>()
  const [arta, setArta] = useState<PublicCourseSchema>()
  const [grte, setGrte] = useState<PublicCourseSchema>()
  const [loading, setLoading] = useState(true)
  const { auth } = useAuth()
  const [showAuthModal, setShowAuthModal] = useState(false)
  const [errModalMsg, setErrModalMsg] = useState("")

  const { CoursesApi } = useApi()
  const { CoursesAuthedApi } = useAuthedApi()

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const getArte = async () => {
    try {
      const response = await CoursesApi.coursesReadCourse("arte")
      if (!response.data) {
        console.log("Get course failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setArte(response.data)
      setLoading(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          console.log("No server response")
        } else if (err.response?.status === 404) {
          console.log("We could not find that course")
        } else if (err.response?.status === 422) {
          console.log("Data validation error")
        } else {
          console.log("Get course failed with unknown error")
        }
      } else {
        console.log("Get course failed with unknown error")
      }
    }
  }

  const getArta = async () => {
    try {
      const response = await CoursesApi.coursesReadCourse("arta")
      if (!response.data) {
        console.log("Get course failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setArta(response.data)
      setLoading(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          console.log("No server response")
        } else if (err.response?.status === 404) {
          console.log("We could not find that course")
        } else if (err.response?.status === 422) {
          console.log("Data validation error")
        } else {
          console.log("Get course failed with unknown error")
        }
      } else {
        console.log("Get course failed with unknown error")
      }
    }
  }

  const getGrte = async () => {
    try {
      const response = await CoursesApi.coursesReadCourse("grte")
      if (!response.data) {
        console.log("Get course failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setGrte(response.data)
      setLoading(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          console.log("No server response")
        } else if (err.response?.status === 404) {
          console.log("We could not find that course")
        } else if (err.response?.status === 422) {
          console.log("Data validation error")
        } else {
          console.log("Get course failed with unknown error")
        }
      } else {
        console.log("Get course failed with unknown error")
      }
    }
  }

  const buy = async (slug: string, quantity: string) => {
    const q = parseInt(quantity)
    if (q < 1) {
      setErrModalMsg("Invalid data, quantity must be larger than 0")
      return
    }

    if (!auth?.user) {
      setShowAuthModal(true)
      return
    }

    try {
      const response = await CoursesAuthedApi.coursesBuyCourse({
        slug: slug,
        quantity: parseInt(quantity),
      })
      if (!response.data) {
        setErrModalMsg("Course purchase failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      window.location.href = response.data.url
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrModalMsg("No server response")
        } else if (err.response?.status === 400) {
          setErrModalMsg(err.response?.data.detail)
        } else if (err.response?.status === 404) {
          setErrModalMsg("We could not find that course")
        } else if (err.response?.status === 422) {
          setErrModalMsg("Data validation error")
        } else {
          setErrModalMsg("Course purchase failed with unknown error")
        }
      } else {
        setErrModalMsg("Course purchase failed with unknown error")
      }
    }
  }

  useEffect(() => {
    getArte()
    getArta()
    getGrte()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className="min-h-screen flex flex-col justify-between">
      <NavbarCustom>
        <NavbarLink href="#intro">Introduction</NavbarLink>
        <NavbarLink href="#certs">Certifications</NavbarLink>
        <NavbarCoursesDropdown />
      </NavbarCustom>
      <div className="mb-auto">
        <div className="container mx-auto">
          <section id="intro">
            <div className="2xl:flex">
              <div className="2xl:w-1/2 my-auto">
                <h1 className="mx-3 md:mx-5 mt-10 text-2xl lg:text-3xl 2xl:text-5xl font-bold text-center text-red-logo">
                  Learn Cloud Hacking & Become HackTricks Training Certified
                </h1>
                <h2 className="mx-3 md:mx-5 mt-8 2xl:mt-16 text-lg lg:text-xl 2xl:text-3xl font-bold text-center dark:text-white">
                  Energize your career with our all-encompassing Cloud Security
                  certification programs.
                </h2>
              </div>
              <div className="2xl:w-1/2">
                <div className="mx-auto w-fit mb-32">
                  <CustomCarousel
                    slides={[
                      {
                        image: (
                          <div className="relative">
                            <img
                              src={ARTE_CAN}
                              className="h-24 sm:h-32 md:h-40 xl:h-48 z-10 mx-auto"
                              alt="ARTE Logo"
                            />
                            <div
                              className="-z-10 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-1 h-1 rounded-full shadow-amber-500 bg-amber-500"
                              style={{
                                boxShadow:
                                  "0px 0px " +
                                  (windowDimensions.width > 1024
                                    ? "100px 60px"
                                    : windowDimensions.width > 640
                                    ? "72px 43px"
                                    : "48px 29px") +
                                  " #f59e0b",
                              }}
                            ></div>
                          </div>
                        ),
                        text: (
                          <Link to={"/courses/arte"}>
                            <h1 className="text-sm md:text-lg text-center text-amber-500 mt-3 md:mt-7">
                              HACKTRICKS ARTE
                            </h1>
                            <h5 className="text-xs md:text-sm text-center text-amber-500">
                              AWS RED TEAM EXPERT
                            </h5>
                          </Link>
                        ),
                      },
                      {
                        image: (
                          <div className="relative">
                            <img
                              src={GRTE_CAN}
                              className="h-24 sm:h-32 md:h-40 xl:h-48 z-10 mx-auto"
                              alt="GRTE Logo"
                            />
                            <div
                              className="-z-10 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-1 h-1 rounded-full shadow-green-600 bg-green-600"
                              style={{
                                boxShadow:
                                  "0px 0px " +
                                  (windowDimensions.width > 1024
                                    ? "100px 60px"
                                    : windowDimensions.width > 640
                                    ? "72px 43px"
                                    : "48px 29px") +
                                  " #16a34a",
                              }}
                            ></div>
                          </div>
                        ),
                        text: (
                          <Link to={"/courses/grte"}>
                            <h1 className="text-sm md:text-lg text-center text-green-600 mt-3 md:mt-7">
                              HACKTRICKS GRTE
                            </h1>
                            <h5 className="text-xs md:text-sm text-center text-green-600">
                              GCP RED TEAM EXPERT
                            </h5>
                          </Link>
                        ),
                      },
                      {
                        image: (
                          <div className="relative">
                            <img
                              src={AzRTE_CAN}
                              className="h-24 sm:h-32 md:h-40 xl:h-48 z-10 mx-auto"
                              alt="AzRTE Logo"
                            />
                            <div
                              className="-z-10 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-1 h-1 rounded-full shadow-sky-400 bg-sky-400"
                              style={{
                                boxShadow:
                                  "0px 0px " +
                                  (windowDimensions.width > 1024
                                    ? "100px 60px"
                                    : windowDimensions.width > 640
                                    ? "72px 43px"
                                    : "48px 29px") +
                                  " #38bdf8",
                              }}
                            ></div>
                          </div>
                        ),
                        text: (
                          <Link to={"#"}>
                            <h1 className="text-sm md:text-lg text-center text-sky-400 mt-3 md:mt-7">
                              HACKTRICKS AzRTE
                            </h1>
                            <h5 className="text-xs md:text-sm text-center text-sky-400">
                              AZURE RED TEAM EXPERT
                            </h5>
                            <h6 className="text-xs text-center text-sky-400">
                              (COMING SOON)
                            </h6>
                          </Link>
                        ),
                      },
                      {
                        image: (
                          <div className="relative">
                            <img
                              src={ARTA_CAN}
                              className="h-24 sm:h-32 md:h-40 xl:h-48 z-10 mx-auto"
                              alt="ARTA Logo"
                            />
                            <div
                              className="-z-10 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-1 h-1 rounded-full shadow-amber-500 bg-amber-500"
                              style={{
                                boxShadow:
                                  "0px 0px " +
                                  (windowDimensions.width > 1024
                                    ? "100px 60px"
                                    : windowDimensions.width > 640
                                    ? "72px 43px"
                                    : "48px 29px") +
                                  " #f59e0b",
                              }}
                            ></div>
                          </div>
                        ),
                        text: (
                          <Link to={"/courses/arta"}>
                            <h1 className="text-sm md:text-lg text-center text-amber-500 mt-3 md:mt-7">
                              HACKTRICKS ARTA
                            </h1>
                            <h5 className="text-xs md:text-sm text-center text-amber-500">
                              AWS RED TEAM APPRENTICE
                            </h5>
                          </Link>
                        ),
                      },

                      {
                        image: (
                          <div className="relative">
                            <img
                              src={GRTA_CAN}
                              className="h-24 sm:h-32 md:h-40 xl:h-48 z-10 mx-auto"
                              alt="GRTA Logo"
                            />
                            <div
                              className="-z-10 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-1 h-1 rounded-full shadow-green-600 bg-green-600"
                              style={{
                                boxShadow:
                                  "0px 0px " +
                                  (windowDimensions.width > 1024
                                    ? "100px 60px"
                                    : windowDimensions.width > 640
                                    ? "72px 43px"
                                    : "48px 29px") +
                                  " #16a34a",
                              }}
                            ></div>
                          </div>
                        ),
                        text: (
                          <Link to={"#"}>
                            <h1 className="text-sm md:text-lg text-center text-green-600 mt-3 md:mt-7">
                              HACKTRICKS GRTA
                            </h1>
                            <h5 className="text-xs md:text-sm text-center text-green-600">
                              GCP RED TEAM APPRENTICE
                            </h5>
                            <h6 className="text-xs text-center text-green-600">
                              (COMING SOON)
                            </h6>
                          </Link>
                        ),
                      },
                      {
                        image: (
                          <div className="relative">
                            <img
                              src={AzRTA_CAN}
                              className="h-24 sm:h-32 md:h-40 xl:h-48 z-10 mx-auto"
                              alt="AzRTA Logo"
                            />
                            <div
                              className="-z-10 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-1 h-1 rounded-full shadow-sky-400 bg-sky-400"
                              style={{
                                boxShadow:
                                  "0px 0px " +
                                  (windowDimensions.width > 1024
                                    ? "100px 60px"
                                    : windowDimensions.width > 640
                                    ? "72px 43px"
                                    : "48px 29px") +
                                  " #38bdf8",
                              }}
                            ></div>
                          </div>
                        ),
                        text: (
                          <Link to={"#"}>
                            <h1 className="text-sm md:text-lg text-center text-sky-400 mt-3 md:mt-7">
                              HACKTRICKS AzRTA
                            </h1>
                            <h5 className="text-xs md:text-sm text-center text-sky-400">
                              AZURE RED TEAM APPRENTICE
                            </h5>
                            <h6 className="text-xs text-center text-sky-400">
                              (COMING SOON)
                            </h6>
                          </Link>
                        ),
                      },
                    ]}
                    radius={
                      windowDimensions.width > 1024
                        ? 250
                        : windowDimensions.width > 640
                        ? 180
                        : 120
                    }
                    x_skew={1}
                    y_skew={
                      windowDimensions.width > 1024
                        ? 0.45
                        : windowDimensions.width > 640
                        ? 0.4
                        : 0.35
                    }
                    z_skew={0.2}
                    scale_skew={0.55}
                    scroll_speed={3}
                  ></CustomCarousel>
                </div>
              </div>
            </div>

            <div className="mx-3 md:mx-24 dark:text-white">
              <div className="md:flex md:flex-row-reverse items-center gap-8 mb-8">
                <div className="md:w-1/4">
                  <img
                    className="max-h-32 md:max-h-48 mb-8 md:mb-0 mx-auto"
                    src={expert_instructors}
                    alt="Expert Instructors"
                  />
                </div>
                <div className="md:w-3/4 bg-neutral-200 dark:bg-neutral-700 p-5 rounded-lg">
                  <h2 className="text-2xl font-bold text-left text-red-logo mb-3">
                    EXPERT INSTRUCTORS
                  </h2>
                  <p className="text-justify dark:text-white">
                    Learn from industry professionals with years of experience
                    in Pentesting, Red Teaming, Web Security, and of course,
                    CI/CD & Cloud Hacking. By learning from their insights and
                    expertise, you'll gain a strong foundation in cybersecurity
                    principles and practices, positioning yourself for success
                    in the field. Leverage their knowledge to stay ahead in the
                    fast-paced cybersecurity domain, ensuring your skills remain
                    relevant and in-demand.
                  </p>
                </div>
              </div>
              <div className="md:flex items-center gap-8 mb-8">
                <div className="md:w-1/4">
                  <img
                    className="max-h-32 md:max-h-48 mb-8 md:mb-0 mx-auto"
                    src={hands_on}
                    alt="Hands-on"
                  />
                </div>
                <div className="md:w-3/4 bg-neutral-200 dark:bg-neutral-700 p-5 rounded-lg">
                  <h2 className="text-2xl font-bold text-left text-red-logo mb-3">
                    HANDS-ON TRAINING
                  </h2>
                  <p className="text-justify dark:text-white">
                    Gain practical experience with tens of different real-world
                    scenarios and tools per course. Our immersive and
                    interactive training modules are designed to develop your
                    hacking skills, helping you apply theoretical knowledge to
                    address actual security challenges effectively and
                    confidently.
                  </p>
                </div>
              </div>
              <div className="md:flex md:flex-row-reverse items-center gap-8 mb-8">
                <div className="md:w-1/4">
                  <img
                    className="max-h-32 md:max-h-48 mb-8 md:mb-0 mx-auto"
                    src={hacktricks_based}
                    alt="HackTricks based"
                  />
                </div>
                <div className="md:w-3/4 bg-neutral-200 dark:bg-neutral-700 p-5 rounded-lg">
                  <h2 className="text-2xl font-bold text-left text-red-logo mb-3">
                    HACKTRICKS BASED
                  </h2>
                  <p className="text-justify dark:text-white">
                    Our certifications, developed by the HackTricks team, are
                    recognized by thousands of security professionals who use
                    HackTricks as their go-to resource for staying up-to-date on
                    the latest trends, techniques, and best practices in the
                    industry. By leveraging the expertise of HackTricks, our
                    certifications ensure that you receive a high quality,
                    industry-relevant education that prepares you for real-world
                    challenges.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <Modal
            show={showAuthModal}
            size="md"
            popup={true}
            onClose={() => {
              setShowAuthModal(false)
            }}
          >
            <Modal.Header />
            <Modal.Body>
              <div className="text-center">
                <ExclamationCircleIcon className="mx-auto mb-4 h-14 w-14 text-red-500 dark:text-red-200" />
                <h3 className="mb-5 text-lg font-normal text-white">
                  You need to sign in to make a purchase
                </h3>
                <div className="flex justify-center gap-4">
                  <Button
                    as={Link}
                    to="/signin"
                    outline={true}
                    className="ml-3 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                  >
                    Sign in
                  </Button>
                  <Button
                    as={Link}
                    to="/signup"
                    className="ml-3 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                  >
                    Create an account
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {loading ? (
            <Loading />
          ) : (
            <>
              <section id="certs" className="my-16">
                <section
                  id="cert_arte"
                  className="mx-3 md:mx-24 py-10 bg-gradient-to-r from-amber-500 to-60% "
                >
                  <div className="md:flex justify-center items-center">
                    <img
                      src={ARTE_CAN}
                      className="md:-mx-10 md:h-96"
                      alt="HackTricks Training Logo"
                    />
                    <div className=" w-full">
                      <h1 className="text-3xl md:text-5xl font-bold text-center text-amber-500 mt-8 md:mt-0">
                        HACKTRICKS ARTE
                      </h1>
                      <h2 className="text-2xl md:text-3xl font-medium text-center text-amber-500">
                        {arte ? arte?.title.toUpperCase() : "No course data"}
                      </h2>
                      <div className="mt-3 md:mt-3 mx-3 md:mx-16 grid grid-cols-1 justify-items-center dark:text-white">
                        <div className="px-10 md:px-16">
                          <p className="text-justify">
                            {arte ? arte?.description : "No course data"}
                          </p>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 justify-items-center dark:text-white">
                        <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                          <div className="flex-none">
                            <h1 className="text-5xl font-bold text-amber-500">
                              20+
                            </h1>
                          </div>
                          <div className="grow ml-3">
                            <h3 className="text-lg font-bold">
                              HOURS OF VIDEO LESSONS
                            </h3>
                          </div>
                        </div>
                        <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                          <div className="flex-none">
                            <h1 className="text-5xl font-bold text-amber-500">
                              70+
                            </h1>
                          </div>
                          <div className="grow ml-3">
                            <h3 className="text-lg font-bold">
                              ESTIMATED HOURS COMPLETION TIME
                            </h3>
                          </div>
                        </div>
                        <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                          <div className="flex-none">
                            <h1 className="text-5xl font-bold text-amber-500">
                              50+
                            </h1>
                          </div>
                          <div className="grow ml-3">
                            <h3 className="text-lg font-bold">
                              HANDS-ON LABS TO TRAIN
                            </h3>
                          </div>
                        </div>
                        <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                          <div className="flex-none">
                            <h1 className="text-5xl font-bold text-amber-500">
                              1
                            </h1>
                          </div>
                          <div className="grow ml-3">
                            <h3 className="text-lg font-bold">
                              EXAM TO PROVE YOUR KNOWLEDGE
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mx-20 mt-10 sm:mx-0 grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-2">
                    <div className="flex flex-col sm:items-end">
                      <Button
                        as={Link}
                        size="xl"
                        className="text-neutral-900 dark:text-white bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-200 hover:dark:bg-neutral-700 !border-amber-500 !ring-amber-500  !ring-2"
                        to={`/courses/${arte?.slug}`}
                      >
                        {/* ENROLL NOW */}
                        More Info
                      </Button>
                    </div>
                    {arte ? (
                      <>
                        <ModalBuyCourse
                          course={arte}
                          cloudProvider="AWS"
                          videoHours={20}
                          daysLab={60}
                          labCount={50}
                          errorMsg={errModalMsg}
                          buy={buy}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
                <section
                  id="cert_arta"
                  className="mx-3 md:mx-24 py-10 bg-gradient-to-l from-amber-500 to-60% "
                >
                  <div className="md:flex justify-center items-center">
                    <img
                      src={ARTA_CAN}
                      className="md:-mx-10 md:h-96  md:order-last"
                      alt="HackTricks Training Logo"
                    />
                    <div className=" w-full">
                      <h1 className="text-3xl md:text-5xl font-bold text-center text-amber-500 mt-8 md:mt-0">
                        HACKTRICKS ARTA
                      </h1>
                      <h2 className="text-2xl md:text-3xl font-medium text-center text-amber-500">
                        {arta ? arta?.title.toUpperCase() : "No course data"}
                      </h2>
                      <div className="mt-3 md:mt-3 mx-3 md:mx-16 grid grid-cols-1 justify-items-center dark:text-white">
                        <div className="px-10 md:px-16">
                          <p className="text-justify">
                            {arta ? arta?.description : "No course data"}
                          </p>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 justify-items-center dark:text-white">
                        <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                          <div className="flex-none">
                            <h1 className="text-5xl font-bold text-amber-500">
                              8+
                            </h1>
                          </div>
                          <div className="grow ml-3">
                            <h3 className="text-lg font-bold">
                              HOURS OF VIDEO LESSONS
                            </h3>
                          </div>
                        </div>
                        <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                          <div className="flex-none">
                            <h1 className="text-5xl font-bold text-amber-500">
                              25+
                            </h1>
                          </div>
                          <div className="grow ml-3">
                            <h3 className="text-lg font-bold">
                              ESTIMATED HOURS COMPLETION TIME
                            </h3>
                          </div>
                        </div>
                        <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                          <div className="flex-none">
                            <h1 className="text-5xl font-bold text-amber-500">
                              20+
                            </h1>
                          </div>
                          <div className="grow ml-3">
                            <h3 className="text-lg font-bold">
                              HANDS-ON LABS TO TRAIN
                            </h3>
                          </div>
                        </div>
                        <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                          <div className="flex-none">
                            <h1 className="text-5xl font-bold text-amber-500">
                              1
                            </h1>
                          </div>
                          <div className="grow ml-3">
                            <h3 className="text-lg font-bold">
                              CERTIFICATE OF COMPLETION
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mx-20 mt-10 sm:mx-0 grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-2">
                    <div className="flex flex-col sm:items-end">
                      <Button
                        as={Link}
                        size="xl"
                        className="text-neutral-900 dark:text-white bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-200 hover:dark:bg-neutral-700 !border-amber-500 !ring-amber-500  !ring-2"
                        to={`/courses/${arta?.slug}`}
                      >
                        {/* ENROLL NOW */}
                        More Info
                      </Button>
                    </div>
                    {arta ? (
                      <>
                        <ModalBuyCourse
                          course={arta}
                          cloudProvider="AWS"
                          videoHours={8}
                          daysLab={30}
                          labCount={20}
                          errorMsg={errModalMsg}
                          buy={buy}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </section>

                <section
                  id="cert_grte"
                  className="mx-3 md:mx-24 py-10 bg-gradient-to-r from-green-600 to-60% "
                >
                  <div className="md:flex justify-center items-center">
                    <img
                      src={GRTE_CAN}
                      className="md:-mx-10 md:h-96"
                      alt="HackTricks GRTE Logo"
                    />
                    <div className=" w-full">
                      <h1 className="text-3xl md:text-5xl font-bold text-center text-green-600 mt-8 md:mt-0">
                        HACKTRICKS GRTE
                      </h1>
                      <h2 className="text-2xl md:text-3xl font-medium text-center text-green-600">
                        {grte ? grte?.title.toUpperCase() : "No course data"}
                      </h2>
                      {grte?.prereleased ? (
                        <div className="w-full">
                          <Alert
                            className="my-6 md:w-1/2 lg:w-1/3 mx-3 md:mx-auto"
                            color="warning"
                            icon={ExclamationTriangleIcon}
                          >
                            This course is in pre-release <br />
                            The predicted release date is{" "}
                            <strong>{grte?.release_date}</strong>
                          </Alert>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="mt-3 md:mt-3 mx-3 md:mx-16 grid grid-cols-1 justify-items-center dark:text-white">
                        <div className="px-10 md:px-16">
                          <p className="text-justify">
                            {grte ? grte?.description : "No course data"}
                          </p>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 justify-items-center dark:text-white">
                        <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                          <div className="flex-none">
                            <h1 className="text-5xl font-bold text-green-600">
                              20+
                            </h1>
                          </div>
                          <div className="grow ml-3">
                            <h3 className="text-lg font-bold">
                              HOURS OF VIDEO LESSONS
                            </h3>
                          </div>
                        </div>
                        <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                          <div className="flex-none">
                            <h1 className="text-5xl font-bold text-green-600">
                              70+
                            </h1>
                          </div>
                          <div className="grow ml-3">
                            <h3 className="text-lg font-bold">
                              ESTIMATED HOURS COMPLETION TIME
                            </h3>
                          </div>
                        </div>
                        <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                          <div className="flex-none">
                            <h1 className="text-5xl font-bold text-green-600">
                              50+
                            </h1>
                          </div>
                          <div className="grow ml-3">
                            <h3 className="text-lg font-bold">
                              HANDS-ON LABS TO TRAIN
                            </h3>
                          </div>
                        </div>
                        <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                          <div className="flex-none">
                            <h1 className="text-5xl font-bold text-green-600">
                              1
                            </h1>
                          </div>
                          <div className="grow ml-3">
                            <h3 className="text-lg font-bold">
                              EXAM TO PROVE YOUR KNOWLEDGE
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mx-20 mt-10 sm:mx-0 grid grid-cols-1 gap-x-20 gap-y-8 sm:grid-cols-2">
                    <div className="flex flex-col sm:items-end">
                      <Button
                        as={Link}
                        size="xl"
                        className="text-neutral-900 dark:text-white bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-200 hover:dark:bg-neutral-700 !border-green-600 !ring-green-600  !ring-2"
                        to={`/courses/${grte?.slug}`}
                      >
                        {/* ENROLL NOW */}
                        More Info
                      </Button>
                    </div>
                    {grte ? (
                      <>
                        <ModalBuyCourse
                          course={grte}
                          cloudProvider="GCP"
                          videoHours={20}
                          daysLab={60}
                          labCount={50}
                          errorMsg={errModalMsg}
                          buy={buy}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
                <section
                  id="cert_grta"
                  className="mx-3 md:mx-24 py-10 bg-gradient-to-l from-green-600 to-60% "
                >
                  <div className="md:flex justify-center items-center">
                    <img
                      src={GRTA_CAN}
                      className="md:-mx-10 md:h-96 md:order-last"
                      alt="HackTricks GRTA Logo"
                    />
                    <div className="">
                      <h1 className="text-3xl md:text-5xl font-bold text-center text-green-600 mt-8 md:mt-0">
                        HACKTRICKS GRTA
                      </h1>
                      <h2 className="text-2xl md:text-3xl font-medium text-center text-green-600">
                        GCP RED TEAM APPRENTICE
                      </h2>
                      <div className="w-full">
                        <Alert
                          className="my-6 md:w-1/2 lg:w-1/3 mx-3 md:mx-auto"
                          color="failure"
                          icon={ExclamationCircleIcon}
                        >
                          This course will be available soon.
                        </Alert>
                      </div>
                      <div className="mt-3 md:mt-3 mx-3 md:mx-16 grid grid-cols-1 justify-items-center dark:text-white">
                        <div className="px-10 md:px-16">
                          <p className="text-justify w-full">
                            Begin your journey in GCP and Google Workspace
                            security, concentrating on foundational concepts
                            such as access controls, introductory data
                            protection techniques, and network security
                            essentials. Learn how to detect and exploit common
                            misconfigurations in the most utilized GCP services.
                            Additionally, discover how to perform proper
                            hardening of GCP and Google Workspace environments
                            to spot and fix complex security issues. Note that
                            GRTA consists of specific lessons from the GRTE
                            certification.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="cert_azrte"
                  className="mx-3 md:mx-24 py-10 bg-gradient-to-r from-sky-400 to-60% "
                >
                  <div className="md:flex justify-center items-center">
                    <img
                      src={AzRTE_CAN}
                      className="md:-mx-10 md:h-96"
                      alt="HackTricks AzRTE Logo"
                    />
                    <div className=" w-full">
                      <h1 className="text-3xl md:text-5xl font-bold text-center text-sky-400 mt-8 md:mt-0">
                        HACKTRICKS AzRTE
                      </h1>
                      <h2 className="text-2xl md:text-3xl font-medium text-center text-sky-400">
                        AZURE RED TEAM EXPERT
                      </h2>
                      <div className="w-full">
                        <Alert
                          className="my-6 md:w-1/2 lg:w-1/3 mx-3 md:mx-auto"
                          color="failure"
                          icon={ExclamationCircleIcon}
                        >
                          This course will be available soon.
                        </Alert>
                      </div>
                      <div className="mt-3 md:mt-3 mx-3 md:mx-16 grid grid-cols-1 justify-items-center dark:text-white">
                        <div className="px-10 md:px-16">
                          <p className="text-justify w-full">
                            Beginning from foundational principles master your
                            expertise in Azure and EntraID security. Learn to
                            spot and exploit misconfigurations in Azure and
                            EntraID services. Master red teaming and whitebox
                            tactics tailored for these platforms, enabling you
                            to tackle complex security vulnerabilities. This
                            certification course aims to elevate security
                            professionals into specialists in Azure and EntraID
                            security.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  id="cert_azrta"
                  className="mx-3 md:mx-24 py-10 bg-gradient-to-l from-sky-400 to-60% "
                >
                  <div className="md:flex justify-center items-center">
                    <img
                      src={AzRTA_CAN}
                      className="md:-mx-10 md:h-96 md:order-last"
                      alt="HackTricks AzRTA Logo"
                    />
                    <div className="">
                      <h1 className="text-3xl md:text-5xl font-bold text-center text-sky-400 mt-8 md:mt-0">
                        HACKTRICKS AzRTA
                      </h1>
                      <h2 className="text-2xl md:text-3xl font-medium text-center text-sky-400">
                        AZURE RED TEAM APPRENTICE
                      </h2>
                      <div className="w-full">
                        <Alert
                          className="my-6 md:w-1/2 lg:w-1/3 mx-3 md:mx-auto"
                          color="failure"
                          icon={ExclamationCircleIcon}
                        >
                          This course will be available soon.
                        </Alert>
                      </div>
                      <div className="mt-3 md:mt-3 mx-3 md:mx-16 grid grid-cols-1 justify-items-center dark:text-white">
                        <div className="px-10 md:px-16">
                          <p className="text-justify w-full">
                            Start your path in Azure and EntraID security,
                            emphasizing basic principles such as access
                            controls, basic data protection strategies, and
                            network security fundamentals. Acquire skills to
                            uncover and manipulate common misconfigurations in
                            the most used Azure services. Additionally, learn
                            how to perform thorough hardening of Azure and
                            EntraID environments to identify and exploit
                            security vulnerabilities. Note that AzRTA consists
                            of specific lessons from the AzRTE certification.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </>
          )}
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Landing
