import { Checkbox, Banner as FlowbiteBanner, Label } from "flowbite-react"
import { FC, PropsWithChildren } from "react"
import { IoNotifications } from "react-icons/io5"
import { HiX } from "react-icons/hi"

export type BannerProps = PropsWithChildren<{
  link?: {
    to: string
    text: string
  }
  id: string
}>

export const Banner: FC<BannerProps> = ({ link, children, id }) => {
  const localDismissedBanners = localStorage.getItem("dismissed_banners")
  let dismissedBannerIds = localDismissedBanners
    ? localDismissedBanners.split(",")
    : []
  return (
    <FlowbiteBanner>
      <div className="flex w-full mx-0 rounded-lg border border-neutral-100 bg-white p-4 shadow-md dark:border-neutral-600 dark:bg-neutral-700 flex-row">
        <div className="w-full">
          <div className="mb-3 flex grow flex-row items-center justify-center">
            <div
              className={
                "inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-blue-100 text-blue-500 dark:bg-blue-800 dark:text-blue-200"
              }
            >
              <IoNotifications className="h-5 w-5" />
            </div>
            <p className="ml-3 flex items-center text-sm font-normal text-neutral-500 dark:text-neutral-400">
              {children}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <Checkbox
              id="dont-show"
              onChange={(e) => {
                if (e.target.checked) {
                  var ids = dismissedBannerIds.includes(id)
                    ? dismissedBannerIds
                    : dismissedBannerIds.concat(id)
                  localStorage.setItem("dismissed_banners", ids.join(","))
                } else {
                  var ids2 = !dismissedBannerIds.includes(id)
                    ? dismissedBannerIds
                    : dismissedBannerIds.splice(
                        dismissedBannerIds.findIndex((value) => {
                          return value === id
                        }),
                        1
                      )
                  localStorage.setItem("dismissed_banners", ids2.join(","))
                }
              }}
            />
            <Label htmlFor="dont-show">Don't show again</Label>
          </div>
        </div>
        <div className="flex shrink-0 items-center">
          <FlowbiteBanner.CollapseButton
            color="gray"
            className="border-0 bg-transparent text-neutral-500 dark:text-neutral-400"
            theme={{
              size: { md: "px-2 py-2 text-sm" },
              color: {
                gray: "ring-cyan-700 border border-neutral-200 bg-white text-neutral-900 focus:text-cyan-700 focus:ring-4 enabled:hover:bg-neutral-100 enabled:hover:text-cyan-700 dark:border-neutral-600 dark:bg-transparent dark:text-neutral-400 dark:enabled:hover:bg-neutral-800 dark:enabled:hover:text-white",
              },
            }}
          >
            <HiX className="h-4 w-4" />
          </FlowbiteBanner.CollapseButton>
        </div>
      </div>
    </FlowbiteBanner>
  )
}
