import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import { PublicCourseSchema } from "../../api"
import useApi from "../../hooks/useApi"
import useAuthedApi from "../../hooks/useAuthedApi"
import { useState, useEffect, useRef } from "react"
import { useParams, useNavigate, Link } from "react-router-dom"
import { isAxiosError } from "axios"
import { Alert, Button, Modal, TextInput } from "flowbite-react"
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import useAuth from "../../hooks/useAuth"
import NavbarPublic from "../../components/navbar/NavbarPublic"
import ARTE_CAN from "../../assets/images/ARTE_CAN.png"
import ARTA_CAN from "../../assets/images/ARTA_CAN.png"
import GRTE_CAN from "../../assets/images/GRTE_CAN.png"
import Syllabus from "../../components/Syllabus"
import FAQs from "../../components/FAQs"
import ReactPlayer from "react-player/youtube"
import Loading from "../../components/Loading"
import { HiCurrencyEuro } from "react-icons/hi"
import ModalCustom from "../../components/modals/ModalCustom"
import TestimonialCarousel from "../../components/TestimonialCarousel"

const Course = () => {
  const [course, setCourse] = useState<PublicCourseSchema>()
  const [loading, setLoading] = useState(true)
  const { CoursesApi } = useApi()
  const { CoursesAuthedApi } = useAuthedApi()
  const navigate = useNavigate()
  const { slug } = useParams()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const [quantity, setQuantity] = useState<string>("1")
  const [validQuantity, setValidQuantity] = useState(false)
  const [quantityFocus, setQuantityFocus] = useState(false)
  const [errModalMsg, setErrModalMsg] = useState("")
  const errModalRef = useRef<HTMLInputElement>(null)
  const { auth } = useAuth()
  const [showAuthModal, setShowAuthModal] = useState(false)

  const getCourse = async (slug: string) => {
    try {
      const response = await CoursesApi.coursesReadCourse(slug)
      if (!response.data) {
        setErrMsg("Get course failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setCourse(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 404) {
          setErrMsg("We could not find that course")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get course failed with unknown error")
        }
      } else {
        setErrMsg("Get course failed with unknown error")
      }
      errRef.current?.focus()
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!slug) {
      navigate("/courses")
      return
    }

    getCourse(slug)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  const buy = async (slug: string, quantity: string) => {
    const q = parseInt(quantity)
    if (q < 1) {
      setErrModalMsg("Invalid data, quantity must be larger than 0")
      return
    }

    if (!auth?.user) {
      setShowAuthModal(true)
      return
    }

    try {
      const response = await CoursesAuthedApi.coursesBuyCourse({
        slug: slug,
        quantity: parseInt(quantity),
      })
      if (!response.data) {
        setErrModalMsg("Course purchase failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      window.location.href = response.data.url
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrModalMsg("No server response")
        } else if (err.response?.status === 400) {
          setErrModalMsg(err.response?.data.detail)
        } else if (err.response?.status === 404) {
          setErrModalMsg("We could not find that course")
        } else if (err.response?.status === 422) {
          setErrModalMsg("Data validation error")
        } else {
          setErrModalMsg("Course purchase failed with unknown error")
        }
      } else {
        setErrModalMsg("Course purchase failed with unknown error")
      }
      errModalRef.current?.focus()
    }
  }

  useEffect(() => {
    const q = parseInt(quantity)
    setValidQuantity(q >= 1)
  }, [quantity])

  return (
    <section className="min-h-screen flex flex-col justify-between">
      {auth ? <NavbarPrivate /> : <NavbarPublic />}

      <div className="mb-auto">
        <div className="container mx-auto">
          <section id="courses">
            <div className={!errMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                className="mt-5"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setErrMsg("")
                }}
              >
                <span ref={errRef}>{errMsg}</span>
              </Alert>
            </div>
            <Modal
              show={showAuthModal}
              size="md"
              popup={true}
              onClose={() => {
                setShowAuthModal(false)
              }}
            >
              <Modal.Header />
              <Modal.Body>
                <div className="text-center">
                  <ExclamationCircleIcon className="mx-auto mb-4 h-14 w-14 text-red-500 dark:text-red-200" />
                  <h3 className="mb-5 text-lg font-normal text-white">
                    You need to sign in to make a purchase
                  </h3>
                  <div className="flex justify-center gap-4">
                    <Button
                      as={Link}
                      to="/signin"
                      outline={true}
                      className="ml-3 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                    >
                      Sign in
                    </Button>
                    <Button
                      as={Link}
                      to="/signup"
                      className="ml-3 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                    >
                      Create an account
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            {loading ? (
              <Loading />
            ) : (
              <>
                {course ? (
                  <div>
                    <div className="md:flex justify-center items-center mt-8">
                      <div>
                        <img
                          src={
                            { arte: ARTE_CAN, arta: ARTA_CAN, grte: GRTE_CAN }[
                              course.slug
                            ]
                          }
                          className="md:mr-3 md:h-56"
                          alt="HackTricks Training Logo"
                        />
                      </div>
                      <div className="">
                        <h1 className="text-3xl md:text-5xl font-bold text-center dark:text-white mt-8 md:mt-0">
                          HACKTRICKS {course.slug.toUpperCase()}
                        </h1>
                        <h2 className="text-2xl md:text-3xl font-medium text-center dark:text-white">
                          {course
                            ? course?.title.toUpperCase()
                            : "No course data"}
                        </h2>
                      </div>
                    </div>
                    {course.prereleased ? (
                      <div className="w-full">
                        <Alert
                          className="my-6 md:w-1/2 lg:w-1/3 mx-3 md:mx-auto"
                          color="warning"
                          icon={ExclamationTriangleIcon}
                        >
                          This course is in pre-release <br />
                          The predicted release date is{" "}
                          <strong>{course.release_date}</strong>
                        </Alert>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="mx-3 md:mx-64 mt-6 md:mt-10">
                      <ReactPlayer
                        controls={true}
                        width="100%"
                        height="100%"
                        className="aspect-video"
                        url={
                          {
                            arte: "https://youtu.be/Aizjgeq1Yek",
                            arta: "https://youtu.be/wbxdotUbF20",
                            grte: "https://youtu.be/aKZHT4eqVKA",
                          }[course.slug]
                        }
                      />
                    </div>
                    <div className="mt-6 md:mt-10 mx-3 md:mx-16 grid grid-cols-1 sm:grid-cols-2 justify-items-center dark:text-white">
                      <div className="px-10 md:px-12 mt-5 md:mt-12">
                        <h3 className="text-xl md:text-2xl font-bold text-center dark:text-white">
                          {
                            {
                              arte: "IN-DEPTH AWS SECURITY TRAINING",
                              arta: "AWS SECURITY TRAINING",
                              grte: "IN-DEPTH GCP SECURITY TRAINING",
                            }[course.slug]
                          }
                        </h3>
                        <p className="text-justify mt-3 md:mt-5">
                          {course ? course?.description : "No course data"}
                        </p>
                      </div>
                      <div className="px-10 md:px-12 mt-5 md:mt-12">
                        <h3 className="text-xl md:text-2xl font-bold text-center dark:text-white">
                          GET HACKTRICKS{" "}
                          {
                            { arte: "ARTE", arta: "ARTA", grte: "GRTE" }[
                              course.slug
                            ]
                          }{" "}
                          CERTIFIED
                        </h3>
                        <p className="text-justify mt-3 md:mt-5">
                          {
                            {
                              arte: `Energize your cloud security career by obtaining the
                          prestigious HackTricks ARTE (AWS Red Team Expert)
                          certification. This highly sought-after credential
                          validates your expertise in AWS security and red
                          teaming, standing out in the field and opening up new
                          career opportunities`,
                              arta: `Energize your cloud security
                          career by obtaining the prestigious HackTricks ARTA
                          (AWS Red Team Apprentice) certification. This highly
                          sought-after credential validates your experience in
                          AWS security, standing out in the field and opening up
                          new career opportunities`,
                              grte: `Energize your cloud security career by obtaining the
                          prestigious HackTricks GRTE (GCP Red Team Expert)
                          certification. This highly sought-after credential
                          validates your expertise in GCP security and red
                          teaming, standing out in the field and opening up new
                          career opportunities`,
                            }[course.slug]
                          }
                        </p>
                      </div>
                    </div>

                    <div className="mx-3 md:mx-16 mt-10 md:mt-10 grid grid-cols-1 sm:grid-cols-2 justify-items-center dark:text-white">
                      <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                        <div className="flex-none">
                          <h1 className="text-5xl font-bold text-amber-500">
                            {
                              {
                                arte: "20+",
                                arta: "8+",
                                grte: "20+",
                              }[course.slug]
                            }
                          </h1>
                        </div>
                        <div className="grow ml-3">
                          <h3 className="text-xl font-bold">
                            HOURS OF VIDEO LESSONS
                          </h3>
                        </div>
                      </div>
                      <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                        <div className="flex-none">
                          <h1 className="text-5xl font-bold text-amber-500">
                            {
                              {
                                arte: "70+",
                                arta: "25+",
                                grte: "70+",
                              }[course.slug]
                            }
                          </h1>
                        </div>
                        <div className="grow ml-3">
                          <h3 className="text-xl font-bold">
                            ESTIMATED HOURS COMPLETION TIME
                          </h3>
                        </div>
                      </div>
                      <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                        <div className="flex-none">
                          <h1 className="text-5xl font-bold text-amber-500">
                            {
                              {
                                arte: "50+",
                                arta: "20+",
                                grte: "50+",
                              }[course.slug]
                            }
                          </h1>
                        </div>
                        <div className="grow ml-3">
                          <h3 className="text-xl font-bold">
                            HANDS-ON LABS TO TRAIN
                          </h3>
                        </div>
                      </div>
                      <div className="flex px-10 md:px-12 mt-5 md:mt-10 items-center">
                        <div className="flex-none">
                          <h1 className="text-5xl font-bold text-amber-500">
                            1
                          </h1>
                        </div>
                        <div className="grow ml-3">
                          <h3 className="text-xl font-bold">
                            {
                              {
                                arte: "EXAM TO PROVE YOUR KNOWLEDGE",
                                arta: "CERTIFICATE OF COMPLETION",
                                grte: "EXAM TO PROVE YOUR KNOWLEDGE",
                              }[course.slug]
                            }
                          </h3>
                        </div>
                      </div>
                    </div>

                    <Syllabus syllabus={course.syllabus} />
                    {/* TODO: Add video for GRTE */}
                    {course.slug === "arte" ? (
                      <div className="my-6 md:my-10 mx-3 md:mx-48 md:flex md:flex-row justify-items-center dark:text-white">
                        <div className="basis-full md:basis-2/3 px-3 md:px-5 my-10 md:my-12 w-full">
                          <ReactPlayer
                            controls={true}
                            width="100%"
                            height="100%"
                            className="aspect-video"
                            url="https://www.youtube.com/watch?v=oQUtvnJ4Idc"
                          />
                        </div>
                        <div className="basis-full md:basis-1/3 px-3 md:px-5 my-10 md:my-12">
                          <h3 className="text-xl md:text-xl font-bold text-center dark:text-white">
                            PREVIEW ARTE LESSON
                          </h3>
                          <p className="text-justify mt-3 md:mt-5">
                            For you to get a feel of what this course is like we
                            have made part of our Red Team Methodology lesson
                            publicly available.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {course.slug === "arte" ? (
                      <>
                        <div className="mx-3 md:mx-32 justify-items-center grow">
                          <h3 className="text-xl md:text-xl font-bold text-center dark:text-white mb-5">
                            TESTIMONIALS
                          </h3>
                          <TestimonialCarousel
                            testimonials={[
                              {
                                author: "Security Researcher",
                                quote:
                                  "This was a great course and exam, OSCP style. Really great course, good instructional up-to-date content in the videos, written documentation via Cloud HackTricks, linked resources to original research, great exercises with cool flag system. I've done a ton of remote trainings (OSCE, OSCP, CRTO, SANS courses, etc.) - including several for offensive Cloud stuff. ARTE was hands down one of my best training experiences. Already recommending this to my colleagues.",
                                rating: 10,
                              },
                              {
                                author: "mightocho",
                                quote:
                                  "I've done some pentest certs in the past, and this is for sure a top tier certification. This course will take you from a newbie to tackle complete AWS pentests, with quality and extensive resources. I've loved it and will for sure take any Hacktricks certifications in the future. I now feel like an AWS ninja.",
                                rating: 9,
                              },
                              {
                                author: "Marcosl",
                                quote:
                                  "Great course, hope this becomes the 'OSCP' of AWS offsec operations.",
                                rating: 9,
                              },
                              {
                                author: "Joel D.",
                                quote:
                                  "This was a great course for me to learn cloud penetration testing against AWS. Cloud had always been a bit of a mystery to me when it comes to testing. However, this course has helped make things much clearer. The structure of the course is well thought out and has helped increase my knowledge of the various services within AWS and how to approach these with a pen-tester mindset. Highly recommended for anyone wanting to learn the skills needed to test AWS infrastructure.",
                                rating: 10,
                              },
                              {
                                author: "chrismeistre",
                                quote:
                                  "I found the course notes and videos very well put together. Each service is covered in detail, and the labs that accompany them are a good way to put the knowledge to use. I can highly recommend this course for anyone interesting in AWS red teaming. I'm looking forward to the Azure and GCP courses!",
                                rating: 10,
                              },
                            ]}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {course.prereleased ? (
                      <div className="w-full">
                        <>
                          <Alert
                            className="my-6 md:w-1/2 lg:w-1/3 mx-3 md:mx-auto"
                            color="warning"
                            icon={ExclamationTriangleIcon}
                          >
                            This course will not be released until{" "}
                            <strong>{course.release_date}</strong>. You can
                            purchase your course voucher now but won't be able
                            to activate it until the release date.
                          </Alert>
                        </>
                      </div>
                    ) : (
                      <></>
                    )}
                    <>
                      {course.promotion ? (
                        <div className="mt-6 text-base leading-relaxed text-neutral-700 dark:text-white  text-center ">
                          <Alert
                            className="my-6 md:w-1/2 lg:w-1/3 mx-3 md:mx-auto text-left"
                            color="failure"
                            icon={ExclamationCircleIcon}
                          >
                            Take advantage of our{" "}
                            <strong>early bird discount!</strong> You can
                            purchase your voucher now with the discount and
                            activate it at any point in the next year.
                          </Alert>
                          <span className="text-lg font-medium">
                            Promotion: -{course.promotion}€
                          </span>
                          <div className="flex justify-center text-lg font-medium">
                            <HiCurrencyEuro
                              size={26}
                              className="align-middle"
                            />
                            <span>Price:</span>
                            <span className="ml-2 text-red-700 dark:text-red-500 line-through">
                              {course.price}€
                            </span>
                            <span className="ml-2 text-green-600 dark:text-green-400">
                              {course.price - course.promotion}€
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-20 text-base leading-relaxed text-neutral-700 dark:text-white text-center">
                          <div className="flex justify-center text-lg font-medium">
                            <HiCurrencyEuro
                              size={26}
                              className="align-middle"
                            />
                            <span>Price:</span>
                            <span className="ml-2">{course.price}€</span>
                          </div>
                        </div>
                      )}
                      {course.max_sales ? (
                        <Alert
                          className="my-6 md:w-1/2 lg:w-1/3 mx-3 md:mx-auto"
                          color="warning"
                          icon={ExclamationTriangleIcon}
                        >
                          To ensure the best quality of our service, for the
                          time being, we are limiting this course to{" "}
                          {course.max_sales} students.
                        </Alert>
                      ) : (
                        <></>
                      )}
                      <ModalCustom title={"Get " + course.title + " now"}>
                        <div className="p-6">
                          <div className="space-y-6">
                            <div className={!errModalMsg ? "sr-only" : ""}>
                              <Alert
                                color="failure"
                                icon={ExclamationTriangleIcon}
                                onDismiss={function onDismiss() {
                                  setErrModalMsg("")
                                }}
                              >
                                <span ref={errModalRef}>{errModalMsg}</span>
                              </Alert>
                            </div>
                            {course.max_sales ? (
                              <Alert
                                className=""
                                color="warning"
                                icon={ExclamationTriangleIcon}
                              >
                                To ensure the best quality of our service, for
                                the time being, we are limiting this course to{" "}
                                {course.max_sales} students.
                              </Alert>
                            ) : (
                              <></>
                            )}
                            {course.prereleased ? (
                              <div className="w-full">
                                <>
                                  <Alert
                                    className=""
                                    color="warning"
                                    icon={ExclamationTriangleIcon}
                                  >
                                    This course will not be released until{" "}
                                    <strong>{course.release_date}</strong>. You
                                    can purchase your course voucher now but
                                    won't be able to activate it until the
                                    release date.
                                  </Alert>
                                </>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="text-base leading-relaxed text-neutral-700 dark:text-white">
                              Includes:
                              <ul className="list-disc list-inside">
                                <li>
                                  {
                                    {
                                      arte: "20+",
                                      arta: "8+",
                                      grte: "20+",
                                    }[course.slug]
                                  }{" "}
                                  hours of video lessons
                                </li>
                                <li>
                                  {
                                    {
                                      arte: "60",
                                      arta: "30",
                                      grte: "60",
                                    }[course.slug]
                                  }{" "}
                                  days of access to{" "}
                                  {
                                    {
                                      arte: "50+",
                                      arta: "20+",
                                      grte: "50+",
                                    }[course.slug]
                                  }{" "}
                                  labs
                                </li>
                                <li>
                                  1{" "}
                                  {
                                    {
                                      arte: "certification exam attempt",
                                      arta: "certificate of completion",
                                      grte: "certification exam attempt",
                                    }[course.slug]
                                  }
                                </li>
                              </ul>
                            </div>
                            <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center">
                              Price: {course.price}€
                            </div>

                            <div className="flex flex-col items-center">
                              <TextInput
                                id="quantity"
                                type="number"
                                placeholder="1"
                                addon="Quantity"
                                required={true}
                                onChange={(e) => {
                                  if (
                                    parseInt(e.target.value) > 0 ||
                                    e.target.value === ""
                                  ) {
                                    setQuantity(e.target.value)
                                  }
                                }}
                                onFocus={() => setQuantityFocus(true)}
                                onBlur={() => setQuantityFocus(false)}
                                value={quantity}
                                aria-invalid={validQuantity ? "false" : "true"}
                                color={
                                  validQuantity
                                    ? ""
                                    : !quantity
                                    ? ""
                                    : "failure"
                                }
                                theme={{ field: { base: "relative w-16" } }}
                                helperText={
                                  <>
                                    <span
                                      className={
                                        quantityFocus &&
                                        quantity &&
                                        !validQuantity
                                          ? ""
                                          : "sr-only"
                                      }
                                    >
                                      <ExclamationCircleIcon className="mr-3 inline h-5 w-5 flex-shrink-0" />
                                      Invalid quantity, must be greater than 0
                                    </span>
                                  </>
                                }
                              />
                            </div>
                            {course.promotion ? (
                              <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center ">
                                <Alert
                                  className=""
                                  color="failure"
                                  icon={ExclamationCircleIcon}
                                >
                                  Take advantage of our{" "}
                                  <strong>early bird discount!</strong> You can
                                  purchase your voucher now with the discount
                                  and activate it at any point in the next year.
                                </Alert>
                                <span className="text-red-700 dark:text-red-500 line-through">
                                  Total:{" "}
                                  {course.price *
                                    (quantity ? parseInt(quantity) : 0)}
                                  €
                                </span>
                                <br />
                                Promotion: -{course.promotion}€ <br />
                                <span className="text-green-600 dark:text-green-400">
                                  New total:{" "}
                                  {course.price *
                                    (quantity ? parseInt(quantity) : 0) -
                                    course.promotion}
                                  €
                                </span>
                              </div>
                            ) : (
                              <div className="text-base leading-relaxed text-neutral-700 dark:text-white text-center">
                                Total:
                                {course.price *
                                  (quantity ? parseInt(quantity) : 0)}
                                €
                              </div>
                            )}
                            <div className="flex flex-col items-center">
                              <Button
                                className="items-center text-neutral-700 dark:text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                type="button"
                                onClick={() => buy(course.slug, quantity)}
                                disabled={!validQuantity}
                              >
                                Buy now
                              </Button>
                            </div>
                          </div>
                        </div>
                      </ModalCustom>
                    </>

                    <FAQs faqs={course.faqs} />
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </section>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default Course
