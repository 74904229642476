// import Footer from "../../components/Footer"
import NavbarPrivate from "../../components/navbar/NavbarPrivate"
import {
  PublicCourseSchema,
  PublicLessonSchema,
  PublicSectionSchema,
  SubtitleURL,
  UserCourseSchema,
  UserLabSchema,
} from "../../api"
import useAuthedApi from "../../hooks/useAuthedApi"
import { useState, useEffect, useRef } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { isAxiosError } from "axios"
import useToasts from "../../hooks/useToasts"
import Loading from "../../components/Loading"
import {
  Accordion,
  Alert,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Label,
  Modal,
  Spinner,
  TextInput,
  Tooltip,
} from "flowbite-react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import ClassroomSidebar from "../../components/sidebar/ClassroomSidebar"
import ReactPlayer from "react-player/"
import {
  BiCheckCircle,
  BiDownload,
  BiLinkAlt,
  BiLinkExternal,
} from "react-icons/bi"
import { LuPartyPopper } from "react-icons/lu"
import ClassroomFlag from "../../components/ClassroomFlag"
import { FaFlask, FaSyncAlt, FaTrashAlt } from "react-icons/fa"
import { IoRocketSharp } from "react-icons/io5"
import { MdMoreTime } from "react-icons/md"
import { HiOutlineExclamationCircle } from "react-icons/hi"
import LessonDeploymentTimer from "../../components/LessonDeploymentTimer"

const Classroom = () => {
  const { toastList, setToastList } = useToasts()
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    window.innerWidth < 640
  )
  const [course, setCourse] = useState<PublicCourseSchema>()
  const [courseData, setCourseData] = useState<UserCourseSchema>()
  const [labData, setLabData] = useState<UserLabSchema>()
  const [section, setSection] = useState<PublicSectionSchema>()
  const [lesson, setLesson] = useState<PublicLessonSchema>()
  const [startSecond, setStartSecond] = useState<number>(0)
  const [secondsLoaded, setSecondsLoaded] = useState(false)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [allFlagsCompleted, setAllFlagsCompleted] = useState<boolean>(false)
  const [completedFlagIds] = useState<string[]>([])
  const [videoURL, setVideoURL] = useState<string>()
  const [subtitleURLs, setSubtitleURLs] = useState<SubtitleURL[]>()
  const [loading, setLoading] = useState(true)
  const [openCourseCompleteModal, setOpenCourseCompleteModal] =
    useState<boolean>(false)
  const { CoursesAuthedApi, LabsAuthedApi, CertificatesAuthedApi } =
    useAuthedApi()
  const navigate = useNavigate()
  const { slug } = useParams()
  const [errMsg, setErrMsg] = useState("")
  const errRef = useRef<HTMLInputElement>(null)
  const [errCompleteMsg, setErrCompleteMsg] = useState("")
  const errCompleteRef = useRef<HTMLInputElement>(null)
  const { hash } = useLocation()

  const [lessonLabDeployErrMsg, setLessonLabDeployErrMsg] = useState("")
  const lessonLabDeployErrRef = useRef<HTMLInputElement>(null)
  const [isDeploying, setIsDeploying] = useState<boolean>(false)

  const [openLessonLabResetModal, setOpenLessonLabResetModal] =
    useState<boolean>(false)
  const [lessonLabResetErrMsg, setLessonLabResetErrMsg] = useState("")
  const lessonLabResetErrRef = useRef<HTMLInputElement>(null)
  const [isResetting, setIsResetting] = useState<boolean>(false)

  const [openLessonLabDestroyModal, setOpenLessonLabDestroyModal] =
    useState<boolean>(false)
  const [lessonLabDestroyErrMsg, setLessonLabDestroyErrMsg] = useState("")
  const lessonLabDestroyErrRef = useRef<HTMLInputElement>(null)
  const [isDestroying, setIsDestroying] = useState<boolean>(false)

  const [openLessonLabExtendModal, setOpenLessonLabExtendModal] =
    useState<boolean>(false)
  const [lessonLabExtendErrMsg, setLessonLabExtendErrMsg] = useState("")
  const lessonLabExtendErrRef = useRef<HTMLInputElement>(null)
  const [isExtending, setIsExtending] = useState<boolean>(false)

  const [generateCertificateErrMsg, setGenerateCertificateErrMsg] = useState("")
  const generateCertificateErrRef = useRef<HTMLInputElement>(null)
  const [certFullName, setCertFullName] = useState<string>("")
  const [generateCertLoading, setGenerateCertLoading] = useState(false)

  const [extraTfVariables, setExtraTfVariables] = useState({})
  const [updateExtraTfVariablesErrMsg, setUpdateExtraTfVariablesErrMsg] =
    useState("")
  const updateExtraTfVariablesErrRef = useRef<HTMLInputElement>(null)
  const [disableDeployBtn, setDisableDeployBtn] = useState<boolean>(false)
  const [updateExtraTfVariablesLoading, setUpdateExtraTfVariablesLoading] =
    useState<boolean>(false)

  const initClass = async (slug: string) => {
    let _course
    let _section
    let _lesson
    let _second

    setErrMsg("")
    setErrCompleteMsg("")
    setAllFlagsCompleted(false)

    // Get course data
    try {
      const response = await CoursesAuthedApi.coursesReadCourse(slug)
      if (!response.data) {
        setErrMsg("Get course failed with unknown error")
        errRef.current?.focus()
        return
      }
      // console.log(JSON.stringify(response?.data))
      _course = response.data
      setCourse(_course)
      if (!hash) {
        if (_course.sections !== undefined && _course.sections !== null) {
          navigate(
            `#${_course.sections[0].slug}/${_course.sections[0].lessons[0].slug}`
          )
        }
        return
      } else {
        // Get section from url hash
        let hash_trimmed = hash.substring(1)
        if (_course.sections !== undefined && _course.sections !== null) {
          _section = _course.sections.find((s) => {
            return s.slug === hash_trimmed.split("/")[0]
          })
          if (!_section) {
            setErrMsg("This section does not exist in this course")
            errRef.current?.focus()
            return
          }
          setSection(_section)

          // Get lesson from url hash
          _lesson = _section.lessons.find((s) => {
            return s.slug === hash_trimmed.split("/")[1].split("?")[0]
          })
          if (!_lesson) {
            setErrMsg(
              "This lesson does not exist in this section of the course"
            )
            errRef.current?.focus()
            return
          }
          setLesson(_lesson)
        }

        // Get second from url hash
        if (hash_trimmed.split("/")[1].split("?").length === 2) {
          _second = hash_trimmed.split("/")[1].split("?")[1].split("second=")[1]
        }

        if (_second) {
          setStartSecond(parseInt(_second))
        }
      }
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 404) {
          setErrMsg("We could not find that course")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get course failed with unknown error")
        }
      } else {
        console.log(err)
        setErrMsg("Get course failed with unknown error")
      }
      errRef.current?.focus()
    }

    // Get video presigned link
    try {
      if (!_lesson || !_section || !_course) {
        setErrMsg("Get video url failed missing course, section or lesson")
        errRef.current?.focus()
        return
      }
      const response = await CoursesAuthedApi.coursesGetVideoPresignedUrl(
        slug,
        _section.slug,
        _lesson.slug
      )
      if (!response.data) {
        setErrMsg("Get video url failed with unknown error")
        errRef.current?.focus()
        return
      }
      // console.log(JSON.stringify(response?.data))

      setVideoURL(response.data.presigned_url)
      setSubtitleURLs(response.data.subtitles_urls)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 404) {
          setErrMsg("We could not find that video url")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get video url failed with unknown error")
        }
      } else {
        setErrMsg("Get video url failed with unknown error")
      }
      errRef.current?.focus()
    }

    // Get user completion data
    try {
      if (!_lesson || !_section || !_course) {
        setErrMsg("Get course data failed missing course, section or lesson")
        return
      }
      const response = await CoursesAuthedApi.coursesReadCourseDataMe(slug)
      if (!response.data) {
        setErrMsg("Get course data failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))

      setCourseData(response.data)
      if (
        (response.data.data as any).sections &&
        (response.data.data as any).sections[_section.id] &&
        (response.data.data as any).sections[_section.id].lessons &&
        (response.data.data as any).sections[_section.id].lessons[_lesson.id] &&
        (response.data.data as any).sections[_section.id].lessons[_lesson.id]
          .completed
      ) {
        setIsCompleted(true)
      }
      if ((response.data.data as any).sections) {
        let courseComplete = false
        if (_course && _course.sections !== undefined) {
          courseComplete = _course.sections
            ?.map((s: PublicSectionSchema) => {
              return (
                (response.data.data as any).sections[s.id] &&
                (response.data.data as any).sections[s.id].completed
              )
            })
            .every((s: any) => {
              return s
            })
        }
        setOpenCourseCompleteModal(courseComplete)
      }
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 404) {
          setErrMsg("We could not find that course data")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get course data failed with unknown error")
        }
      } else {
        setErrMsg("Get course data failed with unknown error")
      }
      errRef.current?.focus()
    }

    // Get user lab completion data
    try {
      if (!_course) {
        setErrMsg("Get lab data failed missing course")
        errRef.current?.focus()
        return
      }
      const labDataResponse = await LabsAuthedApi.labsReadLabMe(slug)
      if (!labDataResponse.data) {
        setErrMsg("Get lab data failed with unknown error")
        errRef.current?.focus()
        return
      }
      // console.log(JSON.stringify(labDataResponse?.data))

      setLabData(labDataResponse.data)
      if (!_lesson) {
        setErrMsg("Get extra lab  data failed missing lesson")
        errRef.current?.focus()
        return
      }
      let variables: any = {}
      _lesson.extra_tf_variables.forEach((variable) => {
        if (
          labDataResponse.data.data &&
          labDataResponse.data.data["extra_tf_variables" as keyof object] &&
          labDataResponse.data.data["extra_tf_variables" as keyof object][
            variable as keyof typeof labDataResponse.data.data
          ]
        ) {
          variables[variable as keyof typeof variables] =
            labDataResponse.data.data["extra_tf_variables" as keyof object][
              variable as keyof typeof labDataResponse.data.data
            ]
        }
      })
      setExtraTfVariables(variables)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 404) {
          setErrMsg("We could not find that lab data")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get lab data failed with unknown error")
        }
      } else {
        setErrMsg("Get lab data failed with unknown error")
      }
      errRef.current?.focus()
    }

    if (!_lesson) {
      setErrMsg("Get video url failed missing lesson")
      errRef.current?.focus()
      return
    }
    if (_lesson.flags.length === 0) {
      setAllFlagsCompleted(true)
    }
  }

  useEffect(() => {
    const interval_30s = setInterval(() => {
      updateLabData()
    }, 30000)

    return () => {
      clearInterval(interval_30s)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lesson, labData])

  useEffect(() => {
    if (!slug) {
      navigate("/courses")
      return
    }

    initClass(slug).finally(() => {
      setLoading(false)
    })

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, hash])

  const completeLesson = async () => {
    setErrCompleteMsg("")
    if (!allFlagsCompleted || !course || !section || !lesson) {
      return
    }
    // Post complete lesson
    try {
      const response = await CoursesAuthedApi.coursesCompleteLesson(
        course.slug,
        section.slug,
        lesson.slug
      )
      if (!response.data.success) {
        setErrCompleteMsg("Complete lesson failed with unknown error")
        errCompleteRef.current?.focus()
        return
      }
      // console.log(JSON.stringify(response?.data))
      setCourseData(response.data.data)
      setIsCompleted(true)
      setToastList(
        toastList.concat({
          type: "success",
          children: `Lesson ${lesson.title} is complete!`,
        })
      )

      let courseComplete = false
      if (course.sections !== undefined) {
        courseComplete = course.sections
          ?.map((s: PublicSectionSchema) => {
            return (
              (response.data.data.data as any).sections[s.id] &&
              (response.data.data.data as any).sections[s.id].completed
            )
          })
          .every((s: any) => {
            return s
          })
      }

      setOpenCourseCompleteModal(courseComplete)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrCompleteMsg("No server response")
        } else if (err.response?.status === 400) {
          setErrCompleteMsg(err.response?.data.detail)
        } else if (err.response?.status === 422) {
          setErrCompleteMsg("Data validation error")
        } else {
          setErrCompleteMsg("Complete lesson failed with unknown error")
        }
      } else {
        setErrCompleteMsg("Complete lesson failed with unknown error")
      }
      errCompleteRef.current?.focus()
    }
  }

  const nextLesson = async () => {
    if (
      !allFlagsCompleted ||
      !isCompleted ||
      !course ||
      !section ||
      !lesson ||
      course.sections === undefined ||
      course.sections === null
    ) {
      return
    }
    window.scrollTo({ top: 0, behavior: "smooth" })
    if (section.lessons[section.lessons.length - 1].id === lesson.id) {
      // If last lesson in section navigate to first lesson of next section
      const nextSectionIndex =
        course.sections.findIndex((s) => {
          return s.id === section.id
        }) + 1
      navigate(
        `#${course.sections[nextSectionIndex].slug}/${course.sections[nextSectionIndex].lessons[0].slug}`
      )
    } else {
      // else get next lesson in current section
      const nextLessonIndex =
        section.lessons.findIndex((l) => {
          return l.id === lesson.id
        }) + 1
      navigate(`#${section.slug}/${section.lessons[nextLessonIndex].slug}`)
    }
  }

  const updateLastSeen = async (second: number) => {
    if (!second || !course || !section || !lesson) {
      return
    }
    try {
      const response = await CoursesAuthedApi.coursesUpdateLastSeen(
        course.slug,
        { lesson: lesson.slug, section: section.slug, second: second }
      )
      if (!response.data.success) {
        setErrMsg("Update last seen failed with unknown error")
        return
      }
      // console.log(JSON.stringify(response?.data))
      setCourseData(response.data.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 400) {
          setErrMsg(err.response?.data.detail)
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Update last seen failed with unknown error")
        }
      } else {
        setErrMsg("Update last seen failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const updateLabData = async () => {
    if (lesson === undefined || labData === undefined) {
      return
    }
    if (lesson.is_lab_deployable !== true) {
      return
    }

    var lesson_state = (labData.data as any).lessons_state[lesson.id]
      ?.state as string

    var expires = new Date(
      (labData?.data as any).lessons_state[lesson.id]?.expires
    )

    if (
      lesson_state !== "PROVISIONING" &&
      lesson_state !== "NUKING" &&
      lesson_state !== "PROVISIONED" &&
      expires > new Date()
    ) {
      return
    }

    try {
      const response = await LabsAuthedApi.labsReadLabMe(labData.lab.slug)
      if (!response.data) {
        setErrMsg("Get lab data failed with unknown error")
        errRef.current?.focus()
        return
      }
      // console.log(JSON.stringify(response?.data))

      setLabData(response.data)
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setErrMsg("No server response")
        } else if (err.response?.status === 404) {
          setErrMsg("We could not find that lab data")
        } else if (err.response?.status === 422) {
          setErrMsg("Data validation error")
        } else {
          setErrMsg("Get lab data failed with unknown error")
        }
      } else {
        setErrMsg("Get lab data failed with unknown error")
      }
      errRef.current?.focus()
    }
  }

  const deployLessonLab = async () => {
    if (isDeploying) {
      return
    }
    setIsDeploying(true)
    if (!labData || !section || !lesson) {
      setLessonLabDeployErrMsg("Missing lab data, lesson or section")
      lessonLabDeployErrRef.current?.focus()
      setIsDeploying(false)
      return
    }
    if (lesson.is_lab_deployable !== true) {
      setLessonLabDeployErrMsg("Lesson has no deployable lab")
      lessonLabDeployErrRef.current?.focus()
      setIsDeploying(false)
      return
    }
    try {
      const response = await LabsAuthedApi.labsDeployLessonLab(
        labData.lab.slug,
        section.slug,
        lesson.slug
      )
      if (!response.data) {
        setLessonLabDeployErrMsg(
          "Lesson lab deployment failed with unknown error"
        )
        setIsDeploying(false)
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setLessonLabDeployErrMsg("Error deploying lesson lab")
        setIsDeploying(false)
        return
      }
      setLabData(response.data.lab_data)
      setToastList(
        toastList.concat({
          type: "success",
          children: `Deployment of labs for lesson ${lesson.title} started successfully!`,
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setLessonLabDeployErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setLessonLabDeployErrMsg("Data validation error")
        } else if (err.response?.status === 400) {
          setLessonLabDeployErrMsg(err.response.data.detail)
        } else {
          setLessonLabDeployErrMsg(
            "Lesson lab deployment failed with unknown error"
          )
        }
      } else {
        setLessonLabDeployErrMsg(
          "Lesson lab deployment failed with unknown error"
        )
      }
      lessonLabDeployErrRef.current?.focus()
    }
    setIsDeploying(false)
  }

  const resetLessonLab = async () => {
    if (isResetting) {
      return
    }
    setIsResetting(true)
    if (!labData || !section || !lesson) {
      setLessonLabResetErrMsg("Missing lab data, lesson or section")
      lessonLabResetErrRef.current?.focus()
      setIsResetting(false)
      return
    }
    if (lesson.is_lab_deployable !== true) {
      setLessonLabResetErrMsg("Lesson has no deployable lab")
      lessonLabResetErrRef.current?.focus()
      setIsResetting(false)
      return
    }
    try {
      const response = await LabsAuthedApi.labsResetLessonLab(
        labData.lab.slug,
        section.slug,
        lesson.slug
      )
      if (!response.data) {
        setLessonLabResetErrMsg("Lesson lab reset failed with unknown error")
        setIsResetting(false)
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setLessonLabResetErrMsg("Error resetting lesson lab")
        setIsResetting(false)
        return
      }
      setOpenLessonLabResetModal(false)
      setLabData(response.data.lab_data)
      setToastList(
        toastList.concat({
          type: "success",
          children: `Reset of labs for lesson ${lesson.title} started successfully!`,
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setLessonLabResetErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setLessonLabResetErrMsg("Data validation error")
        } else if (err.response?.status === 400) {
          setLessonLabResetErrMsg(err.response.data.detail)
        } else {
          setLessonLabResetErrMsg("Lesson lab reset failed with unknown error")
        }
      } else {
        setLessonLabResetErrMsg("Lesson lab reset failed with unknown error")
      }
      lessonLabResetErrRef.current?.focus()
    }
    setIsResetting(false)
  }

  const destroyLessonLab = async () => {
    if (isDestroying) {
      return
    }
    setIsDestroying(true)
    if (!labData || !section || !lesson) {
      setLessonLabDestroyErrMsg("Missing lab data, lesson or section")
      lessonLabDestroyErrRef.current?.focus()
      setIsDestroying(false)
      return
    }
    if (lesson.is_lab_deployable !== true) {
      setLessonLabDestroyErrMsg("Lesson has no deployable lab")
      lessonLabDestroyErrRef.current?.focus()
      setIsDestroying(false)
      return
    }
    try {
      const response = await LabsAuthedApi.labsDestroyLessonLab(
        labData.lab.slug,
        section.slug,
        lesson.slug
      )
      if (!response.data) {
        setLessonLabDestroyErrMsg(
          "Lesson lab destroy failed with unknown error"
        )
        setIsDestroying(false)
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setLessonLabDestroyErrMsg("Error destroying lesson lab")
        setIsDestroying(false)
        return
      }
      setOpenLessonLabDestroyModal(false)
      setLabData(response.data.lab_data)
      setToastList(
        toastList.concat({
          type: "success",
          children: `Destruction of labs for lesson ${lesson.title} started successfully!`,
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setLessonLabDestroyErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setLessonLabDestroyErrMsg("Data validation error")
        } else if (err.response?.status === 400) {
          setLessonLabDestroyErrMsg(err.response.data.detail)
        } else {
          setLessonLabDestroyErrMsg(
            "Lesson lab destruction failed with unknown error"
          )
        }
      } else {
        setLessonLabDestroyErrMsg(
          "Lesson lab destruction failed with unknown error"
        )
      }
      lessonLabDestroyErrRef.current?.focus()
    }
    setIsDestroying(false)
  }

  const extendLessonLab = async () => {
    if (isExtending) {
      return
    }
    setIsExtending(true)
    if (!labData || !section || !lesson) {
      setLessonLabExtendErrMsg("Missing lab data, lesson or section")
      lessonLabExtendErrRef.current?.focus()
      setIsExtending(false)
      return
    }
    if (lesson.is_lab_deployable !== true) {
      setLessonLabExtendErrMsg("Lesson has no deployable lab")
      lessonLabExtendErrRef.current?.focus()
      setIsExtending(false)
      return
    }
    try {
      const response = await LabsAuthedApi.labsExtendLessonLab(
        labData.lab.slug,
        section.slug,
        lesson.slug
      )
      if (!response.data) {
        setLessonLabExtendErrMsg(
          "Lesson lab extension failed with unknown error"
        )
        setIsExtending(false)
        return
      }
      // console.log(JSON.stringify(response?.data))

      if (!response.data.success) {
        setLessonLabExtendErrMsg("Error extending lesson lab duration")
        setIsExtending(false)
        return
      }
      setOpenLessonLabExtendModal(false)
      setLabData(response.data.lab_data)
      setToastList(
        toastList.concat({
          type: "success",
          children: `Labs for lesson ${lesson.title} extended successfully!`,
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setLessonLabExtendErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setLessonLabExtendErrMsg("Data validation error")
        } else if (err.response?.status === 400) {
          setLessonLabExtendErrMsg(err.response.data.detail)
        } else {
          setLessonLabExtendErrMsg(
            "Lesson lab extension failed with unknown error"
          )
        }
      } else {
        setLessonLabExtendErrMsg(
          "Lesson lab extension failed with unknown error"
        )
      }
      lessonLabExtendErrRef.current?.focus()
    }
    setIsExtending(false)
  }

  const generateCertificate = async () => {
    setGenerateCertificateErrMsg("")
    setGenerateCertLoading(true)
    if (!course) {
      setGenerateCertificateErrMsg("Course is undefined")
      generateCertificateErrRef.current?.focus()
      setGenerateCertLoading(false)
      return
    }
    try {
      const response =
        await CertificatesAuthedApi.certificatesGenerateCertificate(course.id, {
          full_name: certFullName,
        })
      if (!response.data) {
        setGenerateCertificateErrMsg(
          "Certificate generation failed with unknown error"
        )
        setGenerateCertLoading(false)
        return
      }
      // console.log(JSON.stringify(response?.data))

      setOpenCourseCompleteModal(false)
      setToastList(
        toastList.concat({
          type: "success",
          children: `Certificate generated and emailed successfully!`,
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setGenerateCertificateErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setGenerateCertificateErrMsg("Data validation error")
        } else if (err.response?.status === 400) {
          setGenerateCertificateErrMsg(err.response?.data.detail)
        } else {
          setGenerateCertificateErrMsg(
            "Certificate generation failed with unknown error"
          )
        }
      } else {
        setGenerateCertificateErrMsg(
          "Certificate generation failed with unknown error"
        )
      }
      generateCertificateErrRef.current?.focus()
    }
    setGenerateCertLoading(false)
  }

  useEffect(() => {
    if (!lesson) {
      setDisableDeployBtn(true)
      return
    }
    let missing = false
    lesson.extra_tf_variables.forEach((variable) => {
      if (
        extraTfVariables[variable as keyof typeof extraTfVariables] ===
        undefined
      ) {
        missing = true
      }
    })
    setDisableDeployBtn(missing)
  }, [extraTfVariables, lesson])

  const updateExtraTfVariables = async () => {
    setUpdateExtraTfVariablesErrMsg("")
    setUpdateExtraTfVariablesLoading(true)
    if (!course) {
      setUpdateExtraTfVariablesErrMsg("Course not found")
      setUpdateExtraTfVariablesLoading(false)
      return
    }
    try {
      const response = await LabsAuthedApi.labsAddExtraDataLab(course.slug, {
        gcp_whitebox_email:
          extraTfVariables[
            "gcp_whitebox_email" as keyof typeof extraTfVariables
          ],
      })
      if (!response.data) {
        setUpdateExtraTfVariablesErrMsg(
          "Adding lab data failed with unknown error"
        )
        setUpdateExtraTfVariablesLoading(false)
        return
      }
      // console.log(JSON.stringify(response?.data))

      setToastList(
        toastList.concat({
          type: "success",
          children: `Successfully added lab data you can now deploy!`,
        })
      )
    } catch (err) {
      if (isAxiosError(err)) {
        if (!err?.response) {
          setUpdateExtraTfVariablesErrMsg("No server response")
        } else if (err.response?.status === 422) {
          setUpdateExtraTfVariablesErrMsg(err.response?.data.detail[0].msg)
        } else if (err.response?.status === 400) {
          setUpdateExtraTfVariablesErrMsg(err.response?.data.detail)
        } else {
          setUpdateExtraTfVariablesErrMsg(
            "Adding lab data failed with unknown error"
          )
        }
      } else {
        setUpdateExtraTfVariablesErrMsg(
          "Adding lab data failed with unknown error"
        )
      }
      updateExtraTfVariablesErrRef.current?.focus()
    }
    setUpdateExtraTfVariablesLoading(false)
  }

  return (
    <>
      <Modal
        show={openCourseCompleteModal}
        dismissible
        size="md"
        popup
        onClose={() => setOpenCourseCompleteModal(false)}
        theme={{
          content: {
            inner:
              "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
          },
        }}
      >
        <Modal.Header />
        <Modal.Body>
          {course?.has_exam ? (
            <>
              <div className="text-center">
                <LuPartyPopper className="mx-auto mb-4 h-14 w-14 text-green-600 dark:text-green-400" />
                <h3 className="mb-5 text-lg font-normal text-neutral-500 dark:text-neutral-400">
                  Congratulations! You have completed all the lessons in the
                  course
                </h3>
                <div className="flex justify-center gap-4">
                  <Button
                    color="success"
                    onClick={() => setOpenCourseCompleteModal(false)}
                  >
                    {/* TODO this button does nothing */}
                    Go to exam
                  </Button>
                  <Button
                    color="gray"
                    onClick={() => setOpenCourseCompleteModal(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="">
                <LuPartyPopper className="mx-auto mb-4 h-14 w-14 text-green-600 dark:text-green-400 text-center" />
                <h3 className="mb-5 text-lg font-normal text-neutral-500 dark:text-neutral-400 text-center">
                  Congratulations! You have completed all the lessons in the
                  course
                </h3>

                {labData?.data && (labData.data as any).certificate ? (
                  <>
                    <div className="flex justify-center gap-4 text-center">
                      <Button
                        color="success"
                        onClick={() =>
                          navigate(
                            `/certificates/${(labData.data as any).certificate}`
                          )
                        }
                      >
                        View certificate
                      </Button>
                      <Button
                        color="gray"
                        onClick={() => setOpenCourseCompleteModal(false)}
                      >
                        Close
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="mb-2 text-neutral-500 dark:text-neutral-400 text-center">
                      It is time to generate your certificate. Please specify
                      the full name you want your certificate to show (this can
                      not be changed later).
                    </p>
                    <div
                      className={!generateCertificateErrMsg ? "sr-only" : ""}
                    >
                      <Alert
                        color="failure"
                        icon={ExclamationTriangleIcon}
                        onDismiss={function onDismiss() {
                          setGenerateCertificateErrMsg("")
                        }}
                      >
                        <span ref={generateCertificateErrRef}>
                          {generateCertificateErrMsg}
                        </span>
                      </Alert>
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label
                          htmlFor="full_name"
                          value="Full name"
                          className="text-left"
                        />
                      </div>
                      <TextInput
                        id="full_name"
                        value={certFullName}
                        onChange={(e) => {
                          setCertFullName(e.target.value)
                        }}
                        required
                        type="text"
                        className="mb-3"
                        theme={{
                          field: {
                            input: {
                              colors: {
                                gray: "bg-neutral-50 border-neutral-300 text-neutral-900 focus:border-red-logo focus:ring-red-logo dark:border-neutral-600 dark:bg-neutral-800 dark:text-white dark:placeholder-neutral-400 dark:focus:border-red-logo dark:focus:ring-red-logo",
                              },
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="flex justify-center gap-4 text-center">
                      <Button
                        isProcessing={generateCertLoading}
                        processingSpinner={<Spinner color="failure" />}
                        color="success"
                        onClick={() => generateCertificate()}
                      >
                        Generate certificate
                      </Button>
                      <Button
                        color="gray"
                        onClick={() => setOpenCourseCompleteModal(false)}
                      >
                        Close
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      {/* <Modal
        show={openCourseCompleteModal}
        dismissible
        size="md"
        popup
        onClose={() => setOpenCourseCompleteModal(false)}
        theme={{
          content: {
            inner:
              "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
          },
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <LuPartyPopper className="mx-auto mb-4 h-14 w-14 text-green-600 dark:text-green-400" />
            <h3 className="mb-5 text-lg font-normal text-neutral-500 dark:text-neutral-400">
              Congratulations! You have completed all the lessons in the course
            </h3>
            <div className="flex justify-center gap-4">
              <Button
                color="success"
                onClick={() => setOpenCourseCompleteModal(false)}
              >
                Go to exam
              </Button>
              <Button
                color="gray"
                onClick={() => setOpenCourseCompleteModal(false)}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      <Modal
        show={openLessonLabResetModal}
        dismissible
        size="md"
        popup
        onClose={() => setOpenLessonLabResetModal(false)}
        theme={{
          content: {
            inner:
              "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
          },
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to reset this lesson's labs?
            </h3>
            <div className={!lessonLabResetErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setLessonLabResetErrMsg("")
                }}
              >
                <span ref={lessonLabResetErrRef}>{lessonLabResetErrMsg}</span>
              </Alert>
            </div>
            <Alert
              className="mb-4"
              color="warning"
              icon={ExclamationTriangleIcon}
            >
              <p className="text-start">
                This reset will destroy the resources needed for this lesson's
                labs and creates them again.
              </p>
              {/* <p className="text-start font-semibold">
                (
                {
                  lessonLabToReset?.last_resets.filter(
                    (d) =>
                      new Date(d) >
                      new Date(now.getTime() - 1 * 1000 * 60 * 60 * 24)
                  ).length
                }
                /3) resets in the last 24 hours.
              </p> */}
              {/* <p className="text-start font-semibold">
                (
                {
                  labToReset?.last_resets.filter(
                    (d) =>
                      new Date(d) >
                      new Date(now.getTime() - 1 * 1000 * 60 * 60 * 1)
                  ).length
                }
                /1) resets in the last hour.
              </p> */}
              <p className="text-start text-xs mt-2">
                If you continue to have issues please contact support in the{" "}
                <a
                  href="https://discord.gg/hRep4RUj7f"
                  target="_blank"
                  rel="noreferrer"
                  className="text-red-logo hover:text-red-900"
                >
                  HackTricks discord server
                </a>
              </p>
            </Alert>
            <div className="flex justify-center gap-4">
              <Button
                color="warning"
                onClick={() => resetLessonLab()}
                isProcessing={isResetting}
                disabled={isResetting}
              >
                <FaSyncAlt className="mr-1" /> Reset
              </Button>
              <Button
                color="gray"
                onClick={() => setOpenLessonLabResetModal(false)}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={openLessonLabDestroyModal}
        dismissible
        size="md"
        popup
        onClose={() => setOpenLessonLabDestroyModal(false)}
        theme={{
          content: {
            inner:
              "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
          },
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to destroy this lesson's labs?
            </h3>
            <div className={!lessonLabDestroyErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setLessonLabDestroyErrMsg("")
                }}
              >
                <span ref={lessonLabDestroyErrRef}>
                  {lessonLabDestroyErrMsg}
                </span>
              </Alert>
            </div>
            <Alert
              className="mb-4"
              color="warning"
              icon={ExclamationTriangleIcon}
            >
              <p className="text-start">
                This will destroy the resources needed for this lesson's labs.
              </p>
            </Alert>
            <div className="flex justify-center gap-4">
              <Button
                color="failure"
                onClick={() => destroyLessonLab()}
                isProcessing={isDestroying}
                disabled={isDestroying}
              >
                <FaTrashAlt className="mr-1" /> Destroy
              </Button>
              <Button
                color="gray"
                onClick={() => setOpenLessonLabDestroyModal(false)}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={openLessonLabExtendModal}
        dismissible
        size="md"
        popup
        onClose={() => setOpenLessonLabExtendModal(false)}
        theme={{
          content: {
            inner:
              "relative rounded-lg bg-white shadow dark:bg-neutral-700 flex flex-col max-h-[90vh]",
          },
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to extend the duration of this lesson's
              labs?
            </h3>
            <div className={!lessonLabExtendErrMsg ? "sr-only" : ""}>
              <Alert
                color="failure"
                icon={ExclamationTriangleIcon}
                onDismiss={function onDismiss() {
                  setLessonLabExtendErrMsg("")
                }}
              >
                <span ref={lessonLabExtendErrRef}>{lessonLabExtendErrMsg}</span>
              </Alert>
            </div>
            <Alert
              className="mb-4"
              color="warning"
              icon={ExclamationTriangleIcon}
            >
              <p className="text-start">
                This will extend the duration of this lesson's labs. The maximum
                duration is <strong>5 hours</strong>.
              </p>
            </Alert>
            <div className="flex justify-center gap-4">
              <Button
                color="purple"
                onClick={() => extendLessonLab()}
                isProcessing={isExtending}
                disabled={isExtending}
              >
                <MdMoreTime size={18} className="mr-1" /> Extend
              </Button>
              <Button
                color="gray"
                onClick={() => setOpenLessonLabExtendModal(false)}
              >
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="fixed top-0 inset-x-0 z-40 flex justify-between items-center">
        <NavbarPrivate />
      </div>

      <ClassroomSidebar
        course={course}
        courseData={courseData}
        labData={labData}
        onCollapseChange={(collapsed) => {
          setSidebarCollapsed(collapsed)
        }}
      />

      {/* Content */}
      <main
        className={
          sidebarCollapsed
            ? "pt-20 pl-0 transition-all ease-in-out duration-300"
            : "pt-20 sm:pl-64 lg:pl-96 transition-all ease-in-out duration-300"
        }
      >
        <div className="container mx-auto">
          {loading ? (
            <Loading />
          ) : (
            <>
              {lesson === undefined ||
              section === undefined ||
              course === undefined ||
              courseData === undefined ? (
                // TODO: make this nice
                <div className="p-5">
                  <Alert
                    className="mx-auto w-1/3"
                    color="failure"
                    icon={ExclamationTriangleIcon}
                    onDismiss={function onDismiss() {
                      setErrMsg("")
                    }}
                  >
                    <span>Could not load lesson</span>
                  </Alert>
                </div>
              ) : (
                <>
                  <div className="p-5">
                    <section className="mb-5" id="classroom">
                      <div className="flex items-center">
                        <Breadcrumb>
                          <Breadcrumb.Item>
                            <p>{course.title}</p>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>{section.title}</Breadcrumb.Item>
                          <Breadcrumb.Item>{lesson.title}</Breadcrumb.Item>
                        </Breadcrumb>
                        {(courseData.data as any).sections &&
                        (courseData.data as any).sections[section.id] &&
                        (courseData.data as any).sections[section.id].lessons &&
                        (courseData.data as any).sections[section.id].lessons[
                          lesson.id
                        ] &&
                        (courseData.data as any).sections[section.id].lessons[
                          lesson.id
                        ].completed ? (
                          <Badge
                            className="ml-3 px-4 text-sm"
                            icon={BiCheckCircle}
                            color="success"
                          >
                            Completed
                          </Badge>
                        ) : (
                          <></>
                        )}
                      </div>
                      <h2 className="mt-5 mx-3 md:mx-20 text-2xl md:text-3xl font-medium dark:text-white">
                        {lesson.title}
                      </h2>
                      <div className={!errMsg ? "sr-only" : ""}>
                        <Alert
                          className="mt-3"
                          color="failure"
                          icon={ExclamationTriangleIcon}
                          onDismiss={function onDismiss() {
                            setErrMsg("")
                          }}
                        >
                          <span ref={errRef}>{errMsg}</span>
                        </Alert>
                      </div>
                      <div className="mx-3 md:mx-20 mt-6 md:mt-10">
                        <ReactPlayer
                          controls={true}
                          width="100%"
                          height="100%"
                          className="aspect-video"
                          url={videoURL}
                          onReady={(player) => {
                            if (!secondsLoaded) {
                              player.seekTo(startSecond)
                              setSecondsLoaded(true)
                            }
                          }}
                          progressInterval={15000}
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nodownload",
                                crossOrigin: "anonymous",
                              },
                              tracks: subtitleURLs?.map((sub, index) => {
                                return {
                                  kind: "captions",
                                  src: sub.url,
                                  srcLang: sub.lang === "spanish" ? "es" : "en",
                                  label:
                                    sub.lang.charAt(0).toUpperCase() +
                                    sub.lang.slice(1),
                                }
                              }),
                            },
                          }}
                          onProgress={(state) => {
                            updateLastSeen(state.playedSeconds)
                          }}
                        />
                        {lesson.slides ? (
                          <div className="pt-2 flex justify-end">
                            <Button
                              as="a"
                              href={lesson.slides}
                              target="_blank"
                              type="button"
                              className="w-fit mt-3 text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                            >
                              <BiDownload className="mr-2" />
                              Download slides
                            </Button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      {lesson.references.length > 0 ? (
                        <Card
                          className="mx-3 md:mx-20 mt-6 md:mt-10"
                          theme={{
                            root: {
                              base: "flex rounded-lg border bg-white dark:bg-neutral-900 shadow-md border-neutral-300 dark:border-neutral-700 dark:bg-neutral-900 flex-col",
                              children:
                                "flex h-full flex-col justify-center gap-0 p-0",
                            },
                          }}
                        >
                          <Accordion
                            alwaysOpen={true}
                            collapseAll={true}
                            flush={true}
                            className="!border-b-0 !divide-neutral-300 !border-neutral-300 dark:!divide-neutral-700 dark:!border-neutral-700"
                            theme={{
                              root: {
                                base: "divide-y divide-neutral-300 border-neutral-300 dark:divide-neutral-700 dark:border-neutral-700 border-b",
                              },
                            }}
                          >
                            <Accordion.Panel>
                              <Accordion.Title
                                theme={{
                                  base: "flex w-full items-center justify-between first:rounded-t-lg last:rounded-b-lg py-5 px-5 text-left font-medium text-neutral-800 dark:text-white !bg-transparent dark:!bg-transparent",
                                }}
                              >
                                <div className=" flex text-2xl font-bold tracking-tight text-neutral-800 dark:text-white">
                                  <BiLinkAlt className="mr-3" />
                                  Resources
                                </div>
                              </Accordion.Title>
                              <Accordion.Content
                                theme={{
                                  base: "py-5 px-5 last:rounded-b-lg border-neutral-100 bg-neutral-100 dark:border-neutral-700 dark:bg-neutral-700 first:rounded-t-lg",
                                }}
                              >
                                <ul className="list-disc list-inside">
                                  {lesson.references.map(
                                    (reference: any, index) => (
                                      <li
                                        key={index}
                                        className="text-neutral-700 dark:text-white"
                                      >
                                        <a
                                          href={reference}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="inline-flex items-center font-medium text-red-logo dark:text-red-500 hover:text-red-900 hover:dark:text-red-800"
                                        >
                                          <BiLinkExternal className="mr-1" />
                                          {reference}
                                        </a>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </Accordion.Content>
                            </Accordion.Panel>
                          </Accordion>
                        </Card>
                      ) : (
                        <></>
                      )}
                      {lesson.flags.length === 0 ? (
                        <></>
                      ) : (
                        <Card
                          className="mx-3 md:mx-20 mt-6 md:mt-10"
                          theme={{
                            root: {
                              base: "flex rounded-lg border border-neutral-200 bg-white shadow-md dark:border-neutral-900 dark:bg-neutral-900 flex-col mx-3 md:mx-20 mt-6 md:mt-10",
                            },
                          }}
                        >
                          <div className="md:flex">
                            <h5 className="flex text-2xl font-bold tracking-tight text-neutral-800 dark:text-white md:my-auto">
                              <FaFlask className="mr-3" />
                              Labs
                            </h5>

                            <Tooltip
                              content={
                                {
                                  LOCKED:
                                    "Lab account provisioning can take up to 30 minutes",
                                  PROVISIONING:
                                    "Lab account provisioning can take up to 30 minutes",
                                  PROVISIONED: "",
                                  DIRTY: "",
                                  NUKING: "",
                                  NUKED: "",
                                }[
                                  (labData?.data as any).state?.[
                                    labData
                                      ? labData?.lab.platform.toLowerCase()
                                      : ""
                                  ]?.state as string
                                ] ??
                                "Lab account provisioning starts automatically once the voucher is activated"
                              }
                              placement="top"
                              theme={{
                                target: "my-auto",
                              }}
                              className={
                                {
                                  LOCKED: "font-sans",
                                  PROVISIONING: "font-sans",
                                  PROVISIONED: "font-sans invisible opacity-0",
                                  DIRTY: "font-sans invisible opacity-0",
                                  NUKING: "font-sans invisible opacity-0",
                                  NUKED: "font-sans invisible opacity-0",
                                }[
                                  (labData?.data as any).state?.[
                                    labData
                                      ? labData?.lab.platform.toLowerCase()
                                      : ""
                                  ]?.state as string
                                ] ?? "font-sans"
                              }
                            >
                              <Badge
                                className="flex justify-center md:justify-start mt-2 md:mt-0 md:ml-3 md:px-3 md:my-auto"
                                color={
                                  {
                                    LOCKED: "warning",
                                    PROVISIONING: "warning",
                                    PROVISIONED: "success",
                                    DIRTY: "failure",
                                    NUKING: "warning",
                                    NUKED: "failure",
                                  }[
                                    (labData?.data as any).state?.[
                                      labData
                                        ? labData?.lab.platform.toLowerCase()
                                        : ""
                                    ]?.state as string
                                  ] ?? "info"
                                }
                              >
                                {{
                                  LOCKED: "Initiating lab account provisioning",
                                  PROVISIONING: "Provisioning lab account",
                                  PROVISIONED:
                                    "Lab account provisioned (" +
                                    ((labData?.data as any).state?.[
                                      labData
                                        ? labData?.lab.platform.toLowerCase()
                                        : ""
                                    ]?.[
                                      labData?.lab.platform === "AWS"
                                        ? "account_id"
                                        : labData?.lab.platform === "GCP"
                                        ? "project_id"
                                        : ""
                                    ] as string) +
                                    ")",
                                  DIRTY: "Error provisioning lab account",
                                  NUKING: "Destroying lab account",
                                  NUKED: "Lab account destroyed",
                                }[
                                  (labData?.data as any).state?.[
                                    labData
                                      ? labData?.lab.platform.toLowerCase()
                                      : ""
                                  ]?.state as string
                                ] ?? "Lab account not provisioned"}
                              </Badge>
                            </Tooltip>
                            {lesson.is_lab_deployable &&
                            ((labData?.data as any).state?.[
                              labData ? labData?.lab.platform.toLowerCase() : ""
                            ]?.state as string) === "PROVISIONED" ? (
                              <>
                                <Tooltip
                                  content={
                                    {
                                      LOCKED:
                                        "Lesson labs provisioning can take over 10 minutes depending on the resources needed",
                                      PROVISIONING:
                                        "Lesson labs provisioning can take over 10 minutes depending on the resources needed",
                                      PROVISIONED: "",
                                      DIRTY: "",
                                      NUKING: "",
                                      NUKED: "",
                                    }[
                                      (labData?.data as any).lessons_state[
                                        lesson.id
                                      ]?.state as string
                                    ] ??
                                    "Click the 'Deploy' button to start this lesson's labs"
                                  }
                                  placement="top"
                                  theme={{
                                    target: "my-auto",
                                  }}
                                  className={
                                    {
                                      LOCKED: "font-sans",
                                      PROVISIONING: "font-sans",
                                      PROVISIONED:
                                        "font-sans invisible opacity-0",
                                      DIRTY: "font-sans invisible opacity-0",
                                      NUKING: "font-sans invisible opacity-0",
                                      NUKED: "font-sans invisible opacity-0",
                                    }[
                                      (labData?.data as any).lessons_state[
                                        lesson.id
                                      ]?.state as string
                                    ] ?? "font-sans"
                                  }
                                >
                                  <Badge
                                    className="flex justify-center md:justify-start mt-2 md:mt-0 md:ml-3 md:px-3 md:my-auto"
                                    color={
                                      {
                                        LOCKED: "warning",
                                        PROVISIONING: "warning",
                                        PROVISIONED: "success",
                                        DIRTY: "failure",
                                        NUKING: "warning",
                                        NUKED: "failure",
                                      }[
                                        (labData?.data as any).lessons_state[
                                          lesson.id
                                        ]?.state as string
                                      ] ?? "info"
                                    }
                                  >
                                    {{
                                      LOCKED:
                                        "Initiating lesson labs provisioning",
                                      PROVISIONING: "Provisioning lesson labs",
                                      PROVISIONED: "Lesson labs provisioned",
                                      DIRTY: "Error provisioning lesson labs",
                                      NUKING: "Destroying lesson labs",
                                      NUKED: "Lesson labs destroyed",
                                    }[
                                      (labData?.data as any).lessons_state[
                                        lesson.id
                                      ]?.state as string
                                    ] ?? "Lesson labs not provisioned"}
                                  </Badge>
                                </Tooltip>

                                <div className="ml-auto md:flex">
                                  <>
                                    {(labData?.data as any).lessons_state[
                                      lesson.id
                                    ] === undefined ||
                                    ![
                                      "PROVISIONING",
                                      "PROVISIONED",
                                      "NUKING",
                                      "NUKED",
                                    ].includes(
                                      (labData?.data as any).lessons_state[
                                        lesson.id
                                      ]?.state
                                    ) ? (
                                      // Lab is not deployed, or changing
                                      <Button
                                        className="mt-3 md:mt-0 md:ml-2 w-full md:w-auto"
                                        color="success"
                                        onClick={() => {
                                          deployLessonLab()
                                        }}
                                        isProcessing={isDeploying}
                                        disabled={
                                          isDeploying || disableDeployBtn
                                        }
                                      >
                                        <IoRocketSharp className="mr-1" />{" "}
                                        Deploy
                                      </Button>
                                    ) : (
                                      // Lab is deployed, or changing
                                      <>
                                        {(labData?.data as any).lessons_state[
                                          lesson.id
                                        ]?.state === "PROVISIONED" ? (
                                          <>
                                            {" "}
                                            <Button
                                              className="mt-3 md:mt-0 md:ml-2 w-full md:w-auto"
                                              color="warning"
                                              onClick={() => {
                                                setOpenLessonLabResetModal(true)
                                              }}
                                              isProcessing={isResetting}
                                              disabled={isResetting}
                                            >
                                              <FaSyncAlt className="mr-1" />{" "}
                                              Reset
                                            </Button>
                                            <Button
                                              className="mt-3 md:mt-0 md:ml-2 w-full md:w-auto"
                                              color="failure"
                                              onClick={() => {
                                                setOpenLessonLabDestroyModal(
                                                  true
                                                )
                                              }}
                                              isProcessing={isDestroying}
                                              disabled={isDestroying}
                                            >
                                              <FaTrashAlt className="mr-1" />{" "}
                                              Destroy
                                            </Button>
                                          </>
                                        ) : (
                                          <></>
                                        )}

                                        {[
                                          "PROVISIONED",
                                          "PROVISIONING",
                                        ].includes(
                                          (labData?.data as any).lessons_state[
                                            lesson.id
                                          ]?.state
                                        ) ? (
                                          <>
                                            <Button
                                              className="mt-3 md:mt-0 md:ml-2 w-full md:w-auto"
                                              color="purple"
                                              onClick={() => {
                                                setOpenLessonLabExtendModal(
                                                  true
                                                )
                                              }}
                                              isProcessing={isExtending}
                                              disabled={isExtending}
                                            >
                                              <MdMoreTime
                                                size={18}
                                                className="mr-1"
                                              />{" "}
                                              Extend
                                            </Button>
                                            <LessonDeploymentTimer
                                              date={
                                                new Date(
                                                  (
                                                    labData?.data as any
                                                  ).lessons_state[
                                                    lesson.id
                                                  ].expires
                                                )
                                              }
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div
                            className={!lessonLabDeployErrMsg ? "sr-only" : ""}
                          >
                            <Alert
                              color="failure"
                              icon={ExclamationTriangleIcon}
                              onDismiss={function onDismiss() {
                                setLessonLabDeployErrMsg("")
                              }}
                            >
                              <span ref={lessonLabDeployErrRef}>
                                {lessonLabDeployErrMsg}
                              </span>
                            </Alert>
                          </div>
                          <>
                            {lesson.extra_tf_variables.length > 0 ? (
                              <Alert
                                color="warning"
                                icon={ExclamationTriangleIcon}
                                theme={{ wrapper: "w-full" }}
                              >
                                <div className="mb-2">
                                  In order to deploy the labs for this lesson we
                                  need some extra data please fill in the data
                                  before deploying
                                </div>
                                {lesson.extra_tf_variables.map(
                                  (extra_tf_variable, index) => (
                                    <div key={index}>
                                      <div className="mb-2 block">
                                        <div className="mb-2 block">
                                          <Label
                                            htmlFor={extra_tf_variable}
                                            value={
                                              {
                                                gcp_whitebox_email:
                                                  "Please enter a Gmail address so we can grant you access to the GCP console",
                                              }[extra_tf_variable] ??
                                              "Error getting extra data title"
                                            }
                                            className="text-neutral-700 dark:text-neutral-700"
                                          />
                                        </div>
                                        <TextInput
                                          id={extra_tf_variable}
                                          type="email"
                                          placeholder={
                                            {
                                              gcp_whitebox_email:
                                                "Gmail address",
                                            }[extra_tf_variable] ??
                                            "Error getting extra data title"
                                          }
                                          required={true}
                                          onChange={(e) => {
                                            let tmp: any = {
                                              ...extraTfVariables,
                                              [extra_tf_variable]:
                                                e.target.value,
                                            }
                                            setExtraTfVariables(tmp)
                                          }}
                                          value={
                                            extraTfVariables
                                              ? extraTfVariables[
                                                  extra_tf_variable as keyof typeof extraTfVariables
                                                ]
                                                ? extraTfVariables[
                                                    extra_tf_variable as keyof typeof extraTfVariables
                                                  ]
                                                : ""
                                              : ""
                                          }
                                        />
                                      </div>
                                    </div>
                                  )
                                )}
                                <div
                                  className={
                                    !updateExtraTfVariablesErrMsg
                                      ? "sr-only"
                                      : ""
                                  }
                                >
                                  <Alert
                                    className="mt-4"
                                    color="failure"
                                    icon={ExclamationTriangleIcon}
                                    onDismiss={function onDismiss() {
                                      setUpdateExtraTfVariablesErrMsg("")
                                    }}
                                  >
                                    <span ref={updateExtraTfVariablesErrRef}>
                                      {updateExtraTfVariablesErrMsg}
                                    </span>
                                  </Alert>
                                </div>
                                <div className="mt-4">
                                  <Button
                                    onClick={() => updateExtraTfVariables()}
                                    className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                    isProcessing={updateExtraTfVariablesLoading}
                                    disabled={updateExtraTfVariablesLoading}
                                  >
                                    Update data
                                  </Button>
                                </div>
                              </Alert>
                            ) : (
                              <></>
                            )}
                          </>
                          {lesson.flags.map((flag) => (
                            <ClassroomFlag
                              key={flag.id}
                              flag={flag}
                              tf_outputs={
                                !lesson.is_lab_deployable ||
                                (labData?.data as any).lessons_state[lesson.id]
                                  ?.state === "PROVISIONED"
                                  ? (labData?.data as any).state?.[
                                      labData
                                        ? labData?.lab.platform.toLowerCase()
                                        : ""
                                    ]?.tf_outputs
                                  : undefined
                              }
                              onChange={(completed: boolean) => {
                                if (completed) {
                                  if (!completedFlagIds.includes(flag.id)) {
                                    completedFlagIds.push(flag.id)
                                  }
                                  if (
                                    Object.values(lesson.flags)
                                      .map((flag: any) => {
                                        return flag
                                      })
                                      .every((f: any) => {
                                        return completedFlagIds.includes(f.id)
                                      })
                                  ) {
                                    setAllFlagsCompleted(true)
                                  }
                                }
                              }}
                            />
                          ))}
                        </Card>
                      )}

                      <div className={!errCompleteMsg ? "sr-only" : ""}>
                        <Alert
                          className="max-w-md mx-3 md:mx-20 mt-6 md:mt-10"
                          color="failure"
                          icon={ExclamationTriangleIcon}
                          onDismiss={function onDismiss() {
                            setErrCompleteMsg("")
                          }}
                        >
                          <span ref={errCompleteRef}>{errCompleteMsg}</span>
                        </Alert>
                      </div>
                      {(courseData.data as any).sections &&
                      (courseData.data as any).sections[section.id] &&
                      (courseData.data as any).sections[section.id].lessons &&
                      (courseData.data as any).sections[section.id].lessons[
                        lesson.id
                      ] &&
                      (courseData.data as any).sections[section.id].lessons[
                        lesson.id
                      ].completed ? (
                        <>
                          {section.lessons
                            .map((l: any) => {
                              return (
                                (courseData.data as any).sections[section.id]
                                  .lessons[l.id] &&
                                (courseData.data as any).sections[section.id]
                                  .lessons[l.id].completed
                              )
                            })
                            .every((s: any) => {
                              return s
                            }) ? (
                            <div className="mx-3 md:mx-20 mt-6 md:mt-10">
                              <Alert color="success">
                                <LuPartyPopper className="inline mr-2" />
                                Congratulations you have completed all the
                                lessons in {section.title}
                              </Alert>
                            </div>
                          ) : (
                            <></>
                          )}
                          {course.sections === undefined ||
                          course.sections === null ? (
                            <></>
                          ) : (
                            <>
                              {course.sections[course.sections.length - 1]
                                .lessons[
                                course.sections[course.sections.length - 1]
                                  .lessons.length - 1
                              ].id === lesson.id ? (
                                <></>
                              ) : (
                                <div className="mx-3 md:mx-20 mt-6 md:mt-10">
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      nextLesson()
                                    }}
                                    className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                                  >
                                    Next lesson
                                  </Button>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <div className="mx-3 md:mx-20 mt-6 md:mt-10">
                          <Tooltip
                            className={allFlagsCompleted ? "opacity-0" : ""}
                            placement="right"
                            content="All flags must be submitted to mark the lesson as complete"
                          >
                            <Button
                              type="button"
                              onClick={() => {
                                completeLesson()
                              }}
                              disabled={!allFlagsCompleted}
                              className="text-white bg-gradient-to-br from-red-500 via-red-logo to-red-900 hover:bg-gradient-to-bl !border-red-logo focus:ring-red-900 dark:focus:ring-red-900 focus:!ring-2"
                            >
                              Complete lesson
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                    </section>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </main>
    </>
  )
}

export default Classroom
